import React from "react";
import { vision } from "./visionData";
import { mission } from "./missionData";

const VisionAndMission = () => {
  return (
    <div style={{ marginBottom: "10%" }}>
      <div className="vision" style={{ height: "max-content" }}>
        <div className="left">
          <h1 style={{ color: "white", marginTop: "0" }}>vision</h1>
          {vision.map((data) => {
            return <p key={data.id}>{data.vision}</p>;
          })}
        </div>
        <div className="right">
          <h1 style={{ color: "white", marginTop: "0" }}>mission</h1>
          <ul>
            {mission.map((data) => {
              return (
                <li style={{ lineHeight: "175%" }} key={data.id}>
                  {data.mission}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VisionAndMission;
