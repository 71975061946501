import React, { useState, useEffect, useRef } from "react";

const MyAccount = () => {
  const u_id = localStorage.getItem("user_id");
  const [edit, setEdit] = useState(false);

  const [, setData] = useState([]);
  const data = useRef([]);

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phn, setPhn] = useState("");
  const [usertype, setUsertype] = useState("");
  const [address, setAddress] = useState("");

  const [usertypeNumber, setUsertypeNumber] = useState("");

  const [username1, setUsername1] = useState("");

  useEffect(() => {
    var axios = require("axios");

    let formData = new FormData();
    formData.append("userid", u_id);

    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/myProfile.php",
      headers: {
        accept: "application/json",
      },
      data: formData,
    };
    axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        console.log(num1);
        if (num1 == 200) {
          data.current = Object.entries(res.data.data);
          setData(data.current);
          console.log("Get data");
          data.current.map((user) => {
            user.slice(1).map((subuser) => {
              setName(subuser.full_name);
              setUsername(subuser.user_name);
              setUsername1(subuser.user_name);
              setEmail(subuser.email_id);
              setPhn(subuser.mobile_number);
              setAddress(subuser.address);
              setUsertype(subuser.user_type);
              setUsertypeNumber(subuser.usertype_id);
            });
          });
        }
        if (num1 == 515) {
          console.log("No data");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data]);

  const editDetails = () => {
    setEdit(true);
  };

  const cancelDetails = () => {
    setEdit(false);
    data.current.map((user) => {
      user.slice(1).map((subuser) => {
        setName(subuser.full_name);
        setUsername(subuser.user_name);
        setEmail(subuser.email_id);
        setPhn(subuser.mobile_number);
        setAddress(subuser.address);
        setUsertype(subuser.user_type);
        setUsertypeNumber(subuser.usertype_id);
      });
    });
  };

  const saveDetails = () => {
    setEdit(false);
    var axios1 = require("axios");

    let formData = new FormData();
    formData.append("user_id", u_id);
    formData.append("full_name", name);
    formData.append("user_name", username);
    formData.append("email_id", email);
    formData.append("mobile_number", phn);
    formData.append("address", address);
    formData.append("user_type", usertype);
    formData.append("usertype_id", usertypeNumber);

    var config1 = {
      method: "post",
      url: "http://aaryanik.com/api_web/updateProfile.php",
      headers: {
        accept: "application/json",
      },
      data: formData,
    };
    axios1(config1)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        console.log(num1);
        if (num1 == 200) {
          console.log("Profile updated successfully");
          alert("Profile updated successfully");
        }
        if (num1 == 515) {
          console.log("Profile not updated successfully");
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    window.location.reload();
  };

  return (
    <React.Fragment>
      <h1>my account</h1>
      <div className="accountContainer" style={{ marginBottom: "20%" }}>
        <div className="main-body">
          <div className="row gutters-sm">
            <div className="col-md-4 mb-3">
              <div className="accountCard">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img
                      src="https://static.thenounproject.com/png/1367727-200.png"
                      alt="Admin"
                      className="rounded-circle"
                      width="150"
                    />
                    <div className="mt-3">
                      <h4>{username1}</h4>
                      <p style={{ textTransform: "capitalize" }}>
                        User Type : {usertype}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="accountCard mb-3">
                <div
                  style={{ border: "black ", borderRadius: "2px" }}
                  className="card-body"
                >
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Full Name</h6>
                    </div>
                    {edit ? (
                      <input
                        className="col-sm-9 text-secondary"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      ></input>
                    ) : (
                      <input
                        className="col-sm-9 text-secondary"
                        value={name}
                        disabled
                      ></input>
                    )}
                  </div>
                  <hr />

                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">User Name</h6>
                    </div>
                    {edit ? (
                      <input
                        className="col-sm-9 text-secondary"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      ></input>
                    ) : (
                      <input
                        className="col-sm-9 text-secondary"
                        value={username}
                        disabled
                      ></input>
                    )}
                  </div>
                  <hr />

                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Email</h6>
                    </div>
                    {edit ? (
                      <input
                        className="col-sm-9 text-secondary"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      ></input>
                    ) : (
                      <input
                        className="col-sm-9 text-secondary"
                        value={email}
                        disabled
                      ></input>
                    )}
                  </div>
                  <hr />

                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Mobile</h6>
                    </div>
                    {edit ? (
                      <input
                        className="col-sm-9 text-secondary"
                        value={phn}
                        onChange={(e) => setPhn(e.target.value)}
                      ></input>
                    ) : (
                      <input
                        className="col-sm-9 text-secondary"
                        value={phn}
                        disabled
                      ></input>
                    )}
                  </div>
                  <hr />

                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Address</h6>
                    </div>
                    {edit ? (
                      <input
                        className="col-sm-9 text-secondary"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      ></input>
                    ) : (
                      <input
                        className="col-sm-9 text-secondary"
                        value={address}
                        disabled
                      ></input>
                    )}
                  </div>
                  <hr />
                  {/* <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">User Type</h6>
                    </div>
                    {edit ? (
                      <select
                        style={{ marginLeft: "0", marginBottom: "0" }}
                        className="col-sm-9 text-secondary"
                        onChange={(e) => setUsertypeNumber(e.target.value)}
                      >
                        <option value="">Choose usertype</option>
                        <option value="1">Buyer</option>
                        <option value="2">Seller</option>
                        <option value="3">Both</option>
                      </select>
                    ) : (
                      <input
                        className="col-sm-9 text-secondary"
                        value={usertype}
                        disabled
                      ></input>
                    )}
                  </div>
                  <hr /> */}
                  <div style={{ display: "inline-flex" }}>
                    {edit ? (
                      <>
                        <a
                          style={{ width: "75px", background: "#331A00" }}
                          className="btn btn-info ml-2"
                          onClick={cancelDetails}
                        >
                          Cancel
                        </a>
                        <a
                          style={{ width: "75px", background: "#331A00" }}
                          className="btn btn-info ml-2"
                          onClick={saveDetails}
                        >
                          Save
                        </a>
                      </>
                    ) : (
                      <a
                        style={{ width: "75px", background: "#331A00" }}
                        className="btn btn-info "
                        onClick={editDetails}
                      >
                        Edit
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MyAccount;
