import React from "react";

const Logo = () => {
  return (
    <div
      style={{ padding: "2% 0", textAlign: "center", backgroundColor: "white" }}
    >
      <img className="logo-img" src="./img/Aryanik1.jpg" alt="" />
    </div>
  );
};

export default Logo;
