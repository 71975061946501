import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import NavBar from "./navbar";
import About from "./about/about";
import Estore from "./estore/estore";
import Cards from "./estore/cards";
import VisionAndMission from "./home/visionAndMission";
import OurProducts from "./home/cards";
import Contact from "./contact/contact";
import Login from "./login/login";
import Register from "./register/register";
import MainBgImage from "./main-bg-image";
import BgImage from "./bg-image";
import Footer from "./footer";
import Cart from "./cart/cart";
import Logo from "./home/logo";
import Details from "./estore/productDetails";
import Seller from "./seller";
import Checkout from "./checkOut";
import MyProfile from "./myprofile";
import MyProducts from "./myproducts";
import Logout from "./logout";
import Approval from "./approvals";
import Orderhistory from "./orderhistory";
import PrevOrders from "./prevOrders";
import Payment from "./payment";
import AdminProduct from "./adminProductDetails";
import Favorite from "./favorites";
import Notifications from "./notifications";
import UserProduct from "./userProduct";

const Routes = () => {
  const [query, setQuery] = useState("");

  const Component1 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <MainBgImage />
        <OurProducts />
        <About />
        <VisionAndMission />
        <Contact />
      </React.Fragment>
    );
  };

  const Component3 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <BgImage />
        <Login />
      </React.Fragment>
    );
  };

  const Component4 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <BgImage />
        <Register />
      </React.Fragment>
    );
  };

  const Component5 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <Cart />
      </React.Fragment>
    );
  };

  const Component6 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <Details />
      </React.Fragment>
    );
  };

  const Component7 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <BgImage />
        <Seller />
      </React.Fragment>
    );
  };

  const Component8 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <Checkout />
      </React.Fragment>
    );
  };

  const Component9 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <MyProfile />
      </React.Fragment>
    );
  };

  const Component10 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <MyProducts />
      </React.Fragment>
    );
  };

  const Component20 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <Logout />
      </React.Fragment>
    );
  };

  const Component21 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <Approval />
      </React.Fragment>
    );
  };

  const Component22 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <Orderhistory />
      </React.Fragment>
    );
  };

  const Component23 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <PrevOrders />
      </React.Fragment>
    );
  };

  const Component24 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <Payment />
      </React.Fragment>
    );
  };

  const Component25 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <AdminProduct />
      </React.Fragment>
    );
  };

  const Component26 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <Notifications />
      </React.Fragment>
    );
  };

  const Component27 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <Favorite />
      </React.Fragment>
    );
  };

  const Component28 = () => {
    return (
      <React.Fragment>
        <Logo />
        <NavBar />
        <UserProduct />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Footer />
      <Switch>
        <Route exact path="/" component={Component1} />
        <Route exact path="/estore">
          <Logo />
          <NavBar />
          <Estore setQuery={setQuery} />
          <Cards query={query} />
        </Route>
        <Route exact path="/login" component={Component3} />
        <Route exact path="/register" component={Component4} />
        <Route exact path="/cart" component={Component5} />
        <Route exact path="/details" component={Component6} />
        <Route exact path="/seller" component={Component7} />
        <Route exact path="/checkout" component={Component8} />
        <Route exact path="/account" component={Component9} />
        <Route exact path="/myproducts" component={Component10} />
        <Route exact path="/logout" component={Component20} />
        <Route exact path="/approvals" component={Component21} />
        <Route exact path="/orderhistory" component={Component22} />
        <Route exact path="/prevorders" component={Component23} />
        <Route exact path="/payment" component={Component24} />
        <Route exact path="/adminproduct" component={Component25} />
        <Route exact path="/notifications" component={Component26} />
        <Route exact path="/favorites" component={Component27} />
        <Route exact path="/userProduct" component={Component28} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
