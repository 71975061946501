import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { IconContext } from "react-icons";
import { MdDelete } from "react-icons/md";

const Cart = () => {
  const u_id = localStorage.getItem("user_id");

  const [count, setCount] = useState(false);

  const [, setData] = useState([]);
  const data = useRef([]);

  const removeItem = (id) => {
    var axios = require("axios");

    let formData = new FormData();
    formData.append("userid", u_id);
    formData.append("productid", id);
    console.log(u_id, id);

    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/removeCartItem.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        if (num1 === 200) {
          console.log("Data deleted successfully");
        }
        if (num1 === 515) {
          console.log("Data not deleted successfully");
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    sendAlert();
    window.location.reload();
  };

  const sendAlert = () => {
    alert("Item removed successfully");
  };

  useEffect(() => {
    var axios = require("axios");

    let formData = new FormData();
    formData.append("userid", u_id);

    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/cartItem.php",
      headers: {
        accept: "application/json",
      },
      data: formData,
    };
    axios(config)
      .then(function (res) {
        console.log(res.data);
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        if (num1 == 200) {
          data.current = Object.entries(res.data.data);
          setData(data.current);
          console.log(data);
          setCount(true);
        } else {
          setCount(false);
        }
      })
      .catch(function (error) {
        setCount(false);
      });
  }, [data]);

  let quantity = 1;

  const decrement = (id) => {
    var val = parseInt(document.getElementById(id).value, 10);
    if (val === 1) {
      val -= 0;
    } else {
      val -= 1;
    }

    document.getElementById(id).value = val;
    quantity = val;
  };

  const increment = (id) => {
    var val = parseInt(document.getElementById(id).value, 10);
    val += 1;
    document.getElementById(id).value = val;
    quantity = val;
  };

  return (
    <React.Fragment>
      <h1>Cart</h1>
      {count ? (
        <>
          <div className="cartContainer" style={{ marginTop: "50px" }}>
            <table id="cart" className="table table-condensed">
              <thead className="table-dark">
                <tr>
                  <th style={{ width: "50%" }}>Product</th>
                  <th style={{ width: "10%" }}>Price</th>
                  <th style={{ width: "8%" }}>Quantity</th>
                  <th style={{ width: "22%" }} className="text-center">
                    Subtotal
                  </th>
                  <th style={{ width: "10%" }}></th>
                </tr>
              </thead>
              {data.current.map((item) => {
                return item.slice(1).map((subitem) => {
                  return (
                    <tbody>
                      <tr>
                        <td data-th="Product">
                          <div className="row">
                            <div className="col-sm-2 hidden-xs">
                              <img
                                src={subitem.product_image}
                                style={{ width: "100px", height: "100px" }}
                                className="img-responsive"
                              />
                            </div>
                            <div className="col-sm-10">
                              <h4>{subitem.product_name}</h4>
                              <h5>Seller: {subitem.full_name}</h5>
                            </div>
                          </div>
                        </td>
                        <td data-th="Price">
                          Rs. {subitem.product_price} {subitem.unit}
                        </td>
                        <td data-th="Quantity">
                          <span style={{ display: "inline-flex" }}>
                            <button
                              className="quantity-btn"
                              style={{
                                borderTopLeftRadius: "0.25rem",
                                borderBottomLeftRadius: "0.25rem",
                              }}
                              onClick={() => decrement(subitem.id)}
                            >
                              -
                            </button>
                            <input
                              id={subitem.id}
                              style={{
                                textAlign: "center",
                                width: "30px",
                                borderColor: "#70ad46",
                              }}
                              value={subitem.quantity}
                              disabled
                            ></input>
                            <button
                              className="quantity-btn"
                              style={{
                                borderTopRightRadius: "0.25rem",
                                borderBottomRightRadius: "0.25rem",
                              }}
                              onClick={() => increment(subitem.id)}
                            >
                              +
                            </button>
                          </span>
                        </td>
                        <td data-th="Subtotal">
                          <span id="cart-span">
                            Rs.{" "}
                            {JSON.parse(subitem.quantity) *
                              JSON.parse(subitem.product_price)}
                            /-
                          </span>
                        </td>
                        <td className="actions" data-th="">
                          <IconContext.Provider
                            value={{ size: "2em" }}
                            className="favorite-icon"
                          >
                            <MdDelete
                              style={{ cursor: "pointer" }}
                              onClick={() => removeItem(subitem.id)}
                            />
                          </IconContext.Provider>
                        </td>
                      </tr>
                    </tbody>
                  );
                });
              })}
            </table>
            <hr></hr>
          </div>

          <div
            className="admin-btn-p"
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20%",
            }}
          >
            <NavLink
              className="btn admin-btn"
              style={{ background: "#331a00", color: "white" }}
              to="/estore"
            >
              Back to Estore
            </NavLink>
            <NavLink
              className="btn admin-btn"
              style={{
                background: "#331a00",
                color: "white",
              }}
              to="/checkOut"
            >
              Proceed to Checkout
            </NavLink>
          </div>
        </>
      ) : (
        <>
          <div className="container" style={{ textAlign: "center" }}>
            <h3>You don't have any items in your cart!</h3>
          </div>
          <div
            className="admin-btn-p"
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20%",
            }}
          >
            <NavLink
              className="btn admin-btn"
              style={{ background: "#331a00", color: "white" }}
              to="/estore"
            >
              Back to Estore
            </NavLink>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default Cart;
