export const data = [
  {
    id: "1",
    para: "Aaryanik Agro Industries is farmer Producer Company registered in 2021. Aaryanik Agro Industries working directly with the farmers which help them to buy or to sell agricultural goods. It will promote best practices of farming, maintain marketing information system, diversifying and raising levels of knowledge and skills in agricultural production and post-harvest processing that adds value to products. It will also help farmers to sell their agricultural goods and provide information regarding to various government schemes.",
  },
  {
    id: "2",
    para: "Farmer Producer Company movement in India has its roots in the amendment in the Company Act in 2002 by allowing producers to register their organization under the Company Act. After a decade, Farmer Producer Company as an institution for the agribusiness were taken up by the farmers with the support from Ministry of Agriculture and Co-operation of Government of India and World Bank funded projects across India. Maharashtra is leading state in the organizing small and marginal farmers through farmer producer companies.",
  },
];
