import React from "react";
import { IconContext } from "react-icons";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";

const Footer = () => {
  return (
    <div className="footer">
      <ul>
        <li>
          <h6
            className="follow-us"
            style={{ paddingTop: "0.25rem", marginRight: "1rem" }}
          >
            Follow Us On:
          </h6>
        </li>
        <li>
          <a
            href="https://www.facebook.com/aaryanik.agro/"
            target="_blank"
            rel="noreferrer"
            style={{ marginRight: "1rem" }}
          >
            <img
              height="20px"
              width="20px"
              src="https://cdn3.iconfinder.com/data/icons/capsocial-round/500/facebook-512.png"
            />
          </a>
        </li>
        {/* <li>
            <a href="#" target="_blank"
              rel="noreferrer" style={{ marginRight: "1rem" }}>

              <img height='20px' width='20px' src='https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/twitter_circle-512.png' />
            </a>
          </li>
          <li>
            <a href="#" target="_blank"
              rel="noreferrer" style={{ marginRight: "1rem" }}>

              <img height='20px' width='20px' src='https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png' />
            </a>
          </li> */}
        <li>
          <h6 style={{ paddingTop: "0.25rem" }}>
            © 2021 AARYANIK AGRO INDUSTRIES FPC LTD
          </h6>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
