import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { IconContext } from "react-icons";
import { MdFavoriteBorder, MdFavorite } from "react-icons/md";

const Details = () => {
  const u_id = localStorage.getItem("user_id");
  const product = JSON.parse(localStorage.getItem("subitem"));

  const [, setData] = useState([]);
  const data = useRef([]);

  let quantity = 1;

  const [fav, setFav] = useState(0);
  const [favMesg, setFavMesg] = useState("");

  useEffect(() => {
    var axios = require("axios");

    let formData = new FormData();
    formData.append("userid", u_id);
    formData.append("productid", product.id);

    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/checkFavorite.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    axios(config)
      .then(function (res) {
        if (res.data.is_favorite == "1") {
          setFav(1);
          setFavMesg("Removed from favorites");
        } else {
          setFav(0);
          setFavMesg("Added to favorites");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [fav]);

  const decrement = () => {
    var val = parseInt(document.getElementById("quantityInput").value, 10);
    if (val === 1) {
      val -= 0;
    } else {
      val -= 1;
    }

    document.getElementById("quantityInput").value = val;
    quantity = val;
  };

  const increment = () => {
    var val = parseInt(document.getElementById("quantityInput").value, 10);
    val += 1;
    document.getElementById("quantityInput").value = val;
    quantity = val;
  };

  const addItem = (id) => {
    var axios = require("axios");

    let formData = new FormData();
    formData.append("userid", u_id);
    formData.append("productid", id);
    formData.append("quantity", quantity);

    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/addToCart.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        if (num1 === 200) {
          console.log("Data inserted successfully");
        }
        if (num1 === 515) {
          console.log("Data not inserted successfully");
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    sendAlert1();
  };

  const sendAlert1 = () => {
    alert("Item added successfully");
  };

  const addToFavorite = (id) => {
    var axios1 = require("axios");

    let formData = new FormData();
    formData.append("userid", u_id);
    formData.append("productid", id);

    var config1 = {
      method: "post",
      url: "http://aaryanik.com/api_web/addToFavorite.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios1(config1)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        if (num1 === 200) {
          alert(favMesg);
        }
        if (num1 === 515) {
          alert(favMesg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <React.Fragment>
      <h1 style={{ color: "#343a40", textAlign: "center" }}>
        {product.product_name}
      </h1>
      <div className="details">
        <img className="details-img" src={product.product_image} alt="" />
      </div>
      <hr />
      <h5 style={{ textAlign: "center" }}>
        Category: {product.product_category}
      </h5>
      <hr />
      <div style={{ textAlign: "center" }}>
        <a
          style={{ cursor: "pointer" }}
          onClick={() => addToFavorite(product.id)}
        >
          <IconContext.Provider
            value={{ size: "2em", color: "red" }}
            className="favorite-icon"
          >
            {fav === 1 ? <MdFavorite /> : <MdFavoriteBorder />}
          </IconContext.Provider>
        </a>
        <h5 style={{ marginLeft: "4%", display: "inline" }}>
          Price: {product.product_price}
        </h5>
        <div id="quantity-div">
          <h5 style={{ marginLeft: "4%", display: "inline" }}>Quantity </h5>
          <button
            className="quantity-btn"
            style={{
              borderTopLeftRadius: "0.25rem",
              borderBottomLeftRadius: "0.25rem",
            }}
            onClick={decrement}
          >
            -
          </button>
          <input
            id="quantityInput"
            disabled
            style={{
              textAlign: "center",
              width: "30px",
              borderColor: "#70ad46",
            }}
            value="1"
          ></input>
          <button
            className="quantity-btn"
            style={{
              borderTopRightRadius: "0.25rem",
              borderBottomRightRadius: "0.25rem",
            }}
            onClick={increment}
          >
            +
          </button>
        </div>
      </div>
      <hr />
      <p style={{ textAlign: "center", fontSize: "large" }}>
        {product.product_desc}
      </p>
      <hr />
      <div style={{ textAlign: "center", marginBottom: "20%" }}>
        <NavLink
          className="btn admin-btn"
          style={{
            background: "#331a00",
            color: "white",
          }}
          to="/estore"
        >
          Back to Estore
        </NavLink>
        <NavLink
          className="btn admin-btn"
          style={{ background: "#331a00", color: "white" }}
          onClick={() => addItem(product.id)}
          to="/cart"
        >
          Add to Cart
        </NavLink>
      </div>
    </React.Fragment>
  );
};

export default Details;
