import React, { useState } from "react";
import { Form, Col } from "react-bootstrap";
import { NavLink, Redirect } from "react-router-dom";

const Payment = () => {
  const u_id = localStorage.getItem("user_id");
  const total = localStorage.getItem("total_amount");

  const [redirect, setRedirect] = useState(false);

  const emptyCart = () => {
    var axios = require("axios");

    let formData = new FormData();
    formData.append("userid", u_id);

    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/emptyCart.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        let num = response.data.success;
        let num1 = parseInt(num, 10);

        if (num1 === 515) {
          alert("Order not placed successfully");
        }
        if (num1 === 200) {
          alert("Order placed successfully");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const placeOrder = () => {
    var axios1 = require("axios");

    let formData = new FormData();
    formData.append("userid", u_id);

    var config1 = {
      method: "post",
      url: "http://aaryanik.com/api_web/placeOrder.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios1(config1)
      .then(function (response) {
        let num = response.data.success;
        let num1 = parseInt(num, 10);
        if (num1 === 515) {
        }
        if (num1 === 200) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const sendEmail = () => {
    var axios2 = require("axios");

    let formData = new FormData();
    formData.append("userid", u_id);
    formData.append("total_amount", total);

    var config2 = {
      method: "post",
      url: "http://aaryanik.com/api_web/placeOrderEmail.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios2(config2)
      .then(function (response) {
        let num = response.data.success;
        let num1 = parseInt(num, 10);
        if (num1 === 515) {
        }
        if (num1 === 200) {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      sendEmail();
      setTimeout(() => {
        placeOrder();
      }, 500);
      setTimeout(() => {
        emptyCart();
      }, 1000);
      setTimeout(() => {
        setRedirect(true);
      }, 1500);
    }

    setValidated(true);
  };

  return (
    <>
      {redirect ? <Redirect to="/"></Redirect> : <></>}
      <h1>Address & Payment Details</h1>
      <div className="container" style={{ marginBottom: "15%" }}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} controlId="validationCustom01">
              <Form.Label>Address</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="House no. / Street name / Nearest Landmark"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid address.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="validationCustom03">
              <Form.Label>City</Form.Label>
              <Form.Control type="text" placeholder="City" required />
              <Form.Control.Feedback type="invalid">
                Please provide a valid city.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom04">
              <Form.Label>State</Form.Label>
              <Form.Control type="text" placeholder="State" required />
              <Form.Control.Feedback type="invalid">
                Please provide a valid state.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom05">
              <Form.Label>Zip</Form.Label>
              <Form.Control type="text" placeholder="Zip" required />
              <Form.Control.Feedback type="invalid">
                Please provide a valid zip.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Group id="payment-tag">
            <Form.Label>Payment Method</Form.Label>
            <Form.Control
              as="select"
              defaultValue="Choose..."
              id="payment"
              required
            >
              <option value="">Choose Payment Way</option>
              <option value="Cash on delivery">Cash on delivery</option>
              <option value="Credit Card">Credit Card</option>
              <option value="Debit Card">Debit Card</option>
              <option value="UPI">UPI</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a payment method.
            </Form.Control.Feedback>
          </Form.Group>
          <div
            className="admin-btn-p"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            <NavLink
              className="btn admin-btn"
              style={{ background: "#331a00", color: "white" }}
              to="/cart"
            >
              Back to Cart
            </NavLink>
            <button type="submit" className="btn admin-btn">
              Place Order
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Payment;
