import React, { useState, useEffect, useRef } from "react";
import Marquee from "react-fast-marquee";
import { NavLink } from "react-router-dom";

const Cards = () => {
  const [, setData] = useState([]);
  const data = useRef([]);

  useEffect(() => {
    var axios = require("axios");
    var config = {
      method: "get",
      url: "http://aaryanik.com/api_web/estore.php",
      headers: {
        accept: "application/json",
      },
    };
    axios(config)
      .then(function (res) {
        data.current = Object.entries(res.data.data);
        setData(data.current);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data]);

  return (
    <React.Fragment>
      <h1>our products</h1>
      <Marquee pauseOnHover="true" gradientWidth="50px">
        <div style={{ display: "inline-flex" }}>
          {data.current.map((item) => {
            return item.slice(1).map((subitem) => {
              return (
                <div key={subitem.p_id}>
                  <div
                    className="card-home zoom"
                    style={{
                      background: "#70ad46",
                      color: "black",
                      marginBottom: "15%",
                    }}
                  >
                    <div className="card-body" style={{ height: "70%" }}>
                      <img
                        height="40px"
                        width="40px"
                        src={subitem.product_image}
                        className="card-img-top mb-3"
                        alt=""
                      />
                      <h5 className="card-title" style={{ marginTop: "auto" }}>
                        {subitem.product_name}
                      </h5>
                      <NavLink
                        to="/estore"
                        className="btn"
                        style={{ background: "#331a00", color: "white" }}
                      >
                        View Details
                      </NavLink>
                    </div>
                  </div>
                </div>
              );
            });
          })}
        </div>
      </Marquee>
    </React.Fragment>
  );
};

export default Cards;
