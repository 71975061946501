export const mission = [
  {
    id: "1",
    mission: "Adoption Of Innovative Technologies.",
  },
  {
    id: "2",
    mission:
      "Improve quality and yield of crops with latest farming technologies.",
  },
  {
    id: "3",
    mission:
      "Develop infrastructure and technical products for each phase of farming.",
  },
  {
    id: "4",
    mission:
      "Trusted partner for Trading, Manufacturing and Processing businesses.",
  },
];
