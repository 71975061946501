import React, { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Redirect, NavLink } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [isUsernameError, setIsUsernameError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const [, setData] = useState([]);
  const data = useRef([]);

  let u_id = "";
  let usertype = "";

  const AuthenticateLogin = () => {
    if (username === "") {
      setIsUsernameError(true);
      setMessage("Incorrect Username or Password!");
    } else {
      setIsUsernameError(false);
    }

    if (password === "") {
      setIsPasswordError(true);
      setMessage("Incorrect Username or Password!");
    } else {
      setIsPasswordError(false);
    }

    if (username !== "" && password !== "") {
      var axios = require("axios");

      let formData = new FormData();
      formData.append("user_name", username);
      formData.append("password", password);

      var config = {
        method: "post",
        url: "http://aaryanik.com/api_web/login.php",
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      axios(config)
        .then(function (res) {
          console.log(JSON.stringify(res.data));
          var num = res.data.success;
          var num1 = parseInt(num, 10);
          console.log(num1);
          if (num1 === 200) {
            data.current = Object.entries(res.data.data);
            setData(data.current);
            data.current.map((item) => {
              item.slice(1).map((subitem) => {
                u_id = subitem.id;
                usertype = subitem.user_type;
              });
            });
            localStorage.setItem("user_id", u_id);
            localStorage.setItem("username", username);
            localStorage.setItem("usertype", usertype);
            setMessage("Logged in successfully");
            setIsSuccess(true);
          }
          if (num1 === 515) {
            setMessage("Invalid username or password");
          }
        })
        .catch(function (error) {
          console.log(error);
          setMessage("Technical problem. Not logged in");
        });
    } else {
      setMessage("Please fill required field");
    }
  };

  return (
    <React.Fragment>
      <div style={{ marginBottom: "10%" }}>
        <div className="cardLogin">
          <div className="cardHeaderLogin">
            <h3>Sign In</h3>
          </div>
          {isSuccess ? (
            <Redirect exact to="/estore" />
          ) : (
            <div>
              <center>
                <h6 style={{ color: "red" }}>{message}</h6>
              </center>
            </div>
          )}
          <div className="cardBodyLogin">
            <div className="form-row justify-content-center">
              <div className="form">
                <Form.Group controlId="formBasicUserName">
                  <Form.Label>User-Name</Form.Label>
                  <Form.Control
                    size="sm"
                    style={{ width: "17rem" }}
                    type="name"
                    placeholder="Enter Username"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    required
                  />
                </Form.Group>
              </div>
            </div>
            <div className="form-row justify-content-center">
              <div className="form">
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    size="sm"
                    style={{ width: "17rem" }}
                    type="password"
                    placeholder="Enter password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                  />
                </Form.Group>
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-block"
                style={{
                  width: "30%",
                  margin: "auto",
                  marginTop: "5%",
                  background: "#70ad46",
                  color: "white",
                }}
                onClick={AuthenticateLogin}
              >
                Login
              </button>
            </div>
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-center links">
              Don't have an account?
              <NavLink to="/register" style={{ color: "white" }}>
                Sign Up
              </NavLink>
            </div>
            <div className="d-flex justify-content-center">
              <a href="#" style={{ color: "white" }}>
                Forgot your password?
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
