import React, { useEffect, useState, useRef } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import { RiShoppingCartLine } from "react-icons/ri";
import { BsFillPersonFill, BsBell } from "react-icons/bs";
import { AiFillHome } from "react-icons/ai";
import { FaStoreAlt } from "react-icons/fa";
import { FaProductHunt } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { IoIosLogOut } from "react-icons/io";
import { FcApproval } from "react-icons/fc";
import { AiOutlineHistory } from "react-icons/ai";
import { HiOutlineLogin } from "react-icons/hi";
import { FaRegistered } from "react-icons/fa";
import { MdFavoriteBorder } from "react-icons/md";
import Badge from "react-bootstrap/Badge";
import { NavLink } from "react-router-dom";
import "./navbar.css";

const NavBar = () => {
  const username = localStorage.getItem("username");
  const u_id = localStorage.getItem("user_id");
  const usertype = localStorage.getItem("usertype");
  const [, setData] = useState([]);
  const data = useRef([]);

  let count_np = 0;
  let count_ns = 0;
  const [, setData1] = useState([]);
  const data1 = useRef([]);

  const [, setData2] = useState([]);
  const data2 = useRef([]);

  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  let count = 0;

  useEffect(() => {
    var axios = require("axios");

    let formData = new FormData();
    formData.append("userid", u_id);

    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/cartItem.php",
      headers: {
        accept: "application/json",
      },
      data: formData,
    };
    axios(config)
      .then(function (res) {
        data.current = Object.entries(res.data.data);
        setData(data.current);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data]);

  data.current.map((item) => {
    return item.slice(1).map((subitem) => {
      count += 1;
    });
  });

  useEffect(() => {
    var axios = require("axios");
    let formData = new FormData();
    formData.append("userid", u_id);
    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/sellerNotification.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    axios(config)
      .then(function (res) {
        data1.current = Object.entries(res.data.data);
        setData1(data1.current);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data1]);

  useEffect(() => {
    var axios5 = require("axios");
    let formData = new FormData();
    formData.append("userid", u_id);
    var config5 = {
      method: "post",
      url: "http://aaryanik.com/api_web/userPending.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    axios5(config5)
      .then(function (res) {
        data2.current = Object.entries(res.data.data);
        setData2(data2.current);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data2]);

  data1.current.map((item) => {
    return item.slice(1).map((subitem) => {
      count_np += 1;
    });
  });

  data2.current.map((item) => {
    return item.slice(1).map((subitem) => {
      count_ns += 1;
    });
  });

  let count1 = count_ns + count_np;

  return (
    <IconContext.Provider value={{ color: "#fff" }} size="1x">
      <div
        className="navbar2 fixed-nav-bar"
        style={{ display: "inline-block" }}
      >
        <a className="menu-bars" style={{ cursor: "pointer" }}>
          <FaIcons.FaBars onClick={showSidebar} />
        </a>
        <div style={{ display: "inline", float: "right", padding: "7px" }}>
          {username ? (
            <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
              <NavLink to="/account" id="hellotag">
                Hello, {username}
              </NavLink>
              <NavLink to="/account">
                <BsFillPersonFill />
              </NavLink>
              <Badge pill variant="light" id="cartcount">
                {count}
              </Badge>
              <NavLink to="/cart">
                <RiShoppingCartLine />
              </NavLink>
              <Badge pill variant="light" id="notificationcount">
                {count1}
              </Badge>
              <NavLink to="/notifications">
                <BsBell />
              </NavLink>
            </IconContext.Provider>
          ) : (
            <></>
          )}
        </div>
      </div>

      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        {u_id ? (
          <ul
            className="nav-menu-items"
            onClick={showSidebar}
            style={{ paddingInlineStart: "0" }}
          >
            {u_id ? (
              <>
                <li className="navbar-toggle" style={{ cursor: "pointer" }}>
                  <a className="menu-bars" style={{ marginLeft: "70%" }}>
                    <AiIcons.AiOutlineClose />
                  </a>
                </li>
                <li className="nav-text">
                  <NavLink className="nav-text-link" to="/">
                    <a style={{ cursor: "pointer" }}>
                      <AiFillHome />
                    </a>
                    <span>Home</span>
                  </NavLink>
                </li>
                <li className="nav-text">
                  <NavLink className="nav-text-link" to="/estore">
                    <a style={{ cursor: "pointer" }}>
                      <FaStoreAlt />
                    </a>
                    <span>E-Store</span>
                  </NavLink>
                </li>
              </>
            ) : (
              <></>
            )}
            {usertype === "Buyer" && !(usertype == "Admin") ? (
              <li className="nav-text">
                <NavLink className="nav-text-link" to="/seller">
                  <a style={{ cursor: "pointer" }}>
                    <CgProfile />
                  </a>
                  <span>Create Seller Account</span>
                </NavLink>
              </li>
            ) : usertype === "Seller" ||
              usertype === "Both" ||
              usertype === "Admin" ? (
              <li className="nav-text">
                <NavLink className="nav-text-link" to="/myproducts">
                  <a style={{ cursor: "pointer" }}>
                    <FaProductHunt />
                  </a>
                  <span>My Products</span>
                </NavLink>
              </li>
            ) : (
              <></>
            )}
            {u_id ? (
              !(usertype == "Admin") ? (
                <>
                  <li className="nav-text">
                    <NavLink className="nav-text-link" to="/prevorders">
                      <a style={{ cursor: "pointer" }}>
                        <IoIosLogOut />
                      </a>
                      <span>Previous Orders</span>
                    </NavLink>
                  </li>
                  <li className="nav-text">
                    <NavLink className="nav-text-link" to="/favorites">
                      <a style={{ cursor: "pointer" }}>
                        <MdFavoriteBorder />
                      </a>
                      <span>Favorites</span>
                    </NavLink>
                  </li>
                  <li className="nav-text">
                    <NavLink className="nav-text-link" to="/account">
                      <a style={{ cursor: "pointer" }}>
                        <CgProfile />
                      </a>
                      <span>My Profile</span>
                    </NavLink>
                  </li>
                  <li className="nav-text">
                    <NavLink className="nav-text-link" to="/logout">
                      <a style={{ cursor: "pointer" }}>
                        <IoIosLogOut />
                      </a>
                      <span>Logout</span>
                    </NavLink>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-text">
                    <NavLink className="nav-text-link" to="/approvals">
                      <a style={{ cursor: "pointer" }}>
                        <FcApproval />
                      </a>
                      <span>Approval Requests</span>
                    </NavLink>
                  </li>
                  <li className="nav-text">
                    <NavLink className="nav-text-link" to="/orderhistory">
                      <a style={{ cursor: "pointer" }}>
                        <AiOutlineHistory />
                      </a>
                      <span>Order History</span>
                    </NavLink>
                  </li>
                  <li className="nav-text">
                    <NavLink className="nav-text-link" to="/favorites">
                      <a style={{ cursor: "pointer" }}>
                        <MdFavoriteBorder />
                      </a>
                      <span>Favorites</span>
                    </NavLink>
                  </li>
                  <li className="nav-text">
                    <NavLink className="nav-text-link" to="/account">
                      <a style={{ cursor: "pointer" }}>
                        <CgProfile />
                      </a>
                      <span>My Profile</span>
                    </NavLink>
                  </li>
                  <li className="nav-text">
                    <NavLink className="nav-text-link" to="/logout">
                      <a style={{ cursor: "pointer" }}>
                        <IoIosLogOut />
                      </a>
                      <span>Logout</span>
                    </NavLink>
                  </li>
                </>
              )
            ) : (
              <>
                <li className="nav-text">
                  <NavLink className="nav-text-link" to="/login">
                    <a style={{ cursor: "pointer" }}>
                      <HiOutlineLogin />
                    </a>
                    <span>Login</span>
                  </NavLink>
                </li>
                <li className="nav-text">
                  <NavLink className="nav-text-link" to="/register">
                    <a style={{ cursor: "pointer" }}>
                      <FaRegistered />
                    </a>
                    <span>Register</span>
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        ) : (
          <>
            <ul
              className="nav-menu-items"
              onClick={showSidebar}
              style={{ paddingInlineStart: "0" }}
            >
              <li className="navbar-toggle" style={{ cursor: "pointer" }}>
                <a className="menu-bars" style={{ marginLeft: "70%" }}>
                  <AiIcons.AiOutlineClose />
                </a>
              </li>
              <li className="nav-text">
                <NavLink className="nav-text-link" to="/">
                  <a style={{ cursor: "pointer" }}>
                    <AiFillHome />
                  </a>
                  <span>Home</span>
                </NavLink>
              </li>
              <li className="nav-text">
                <NavLink className="nav-text-link" to="/estore">
                  <a style={{ cursor: "pointer" }}>
                    <FaStoreAlt />
                  </a>
                  <span>E-Store</span>
                </NavLink>
              </li>
              <li className="nav-text">
                <NavLink className="nav-text-link" to="/login">
                  <a style={{ cursor: "pointer" }}>
                    <HiOutlineLogin />
                  </a>
                  <span>Login</span>
                </NavLink>
              </li>
              <li className="nav-text">
                <NavLink className="nav-text-link" to="/register">
                  <a style={{ cursor: "pointer" }}>
                    <FaRegistered />
                  </a>
                  <span>Register</span>
                </NavLink>
              </li>
            </ul>
          </>
        )}
      </nav>
    </IconContext.Provider>
  );
};

export default NavBar;
