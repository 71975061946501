import React, { useState, useEffect, useRef } from "react";
import { Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const PrevOrders = () => {
  const u_id = localStorage.getItem("user_id");
  const user_type = localStorage.getItem("usertype");

  const [sold, setSold] = useState(true);
  const [bought, setBought] = useState(false);

  const [count, setCount] = useState(false);

  const showSold = () => {
    setSold(true);
    setBought(false);
  };

  const showBought = () => {
    setSold(false);
    setBought(true);
  };

  const [, setData] = useState([]);
  const data = useRef([]);

  const [, setData1] = useState([]);
  const data1 = useRef([]);

  useEffect(() => {
    var axios = require("axios");

    let formData = new FormData();
    formData.append("userid", u_id);

    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/previousOrders.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    axios(config)
      .then(function (res) {
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        if (num1 == 200) {
          data.current = Object.entries(res.data.data);
          setData(data.current);
          setCount(true);
        } else {
          // setCount(false);
        }
      })
      .catch(function (error) {
        // setCount(false);
      });
  }, [data]);

  useEffect(() => {
    var axios1 = require("axios");

    let formData = new FormData();
    formData.append("userid", u_id);

    var config1 = {
      method: "post",
      url: "http://aaryanik.com/api_web/sellerSoldOrd.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    axios1(config1)
      .then(function (res) {
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        if (num1 == 200) {
          data1.current = Object.entries(res.data.data);
          setData1(data1.current);
          setCount(true);
        } else {
          // setCount(false);
        }
      })
      .catch(function (error) {
        // setCount(false);
      });
  }, [data1]);

  return (
    <>
      <h1>My Orders</h1>
      {count ? (
        <>
          {user_type == "Buyer" ? (
            <Table
              striped
              bordered
              hover
              size="sm"
              style={{ marginBottom: "15%" }}
            >
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Product</th>
                  <th>Seller</th>
                  <th>Quantity</th>
                  <th>Total Price</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.current.map((item) => {
                  return item.slice(1).map((subitem) => {
                    return (
                      <tr>
                        <td>{subitem.id}</td>
                        <td>{subitem.product_name}</td>
                        <td>{subitem.seller_name}</td>
                        <td>{subitem.quantity}</td>
                        <td>
                          Rs.{" "}
                          {JSON.parse(subitem.product_price) *
                            JSON.parse(subitem.quantity)}
                          /-
                        </td>
                        <td>{subitem.status == 1 ? "Delivered" : "Pending"}</td>
                      </tr>
                    );
                  });
                })}
              </tbody>
            </Table>
          ) : (
            <>
              <div
                className="admin-btn-p"
                style={{ textAlign: "center", marginTop: "20px" }}
              >
                <button className="btn admin-btn" onClick={showSold}>
                  Sold Products
                </button>
                <button className="btn admin-btn" onClick={showBought}>
                  Bought Products
                </button>
                <hr />
              </div>
              {sold ? (
                <Table
                  striped
                  bordered
                  hover
                  size="sm"
                  style={{ marginBottom: "15%" }}
                >
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Product</th>
                      <th>Buyer</th>
                      <th>Quantity</th>
                      <th>Total Price</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data1.current.map((item) => {
                      return item.slice(1).map((subitem) => {
                        return (
                          <tr>
                            <td>{subitem.id}</td>
                            <td>{subitem.product_name}</td>
                            <td>{subitem.buyer_name}</td>
                            <td>{subitem.quantity}</td>
                            <td>
                              Rs.{" "}
                              {JSON.parse(subitem.product_price) *
                                JSON.parse(subitem.quantity)}
                              /-
                            </td>
                            <td>
                              {subitem.status == 1 ? "Delivered" : "Pending"}
                            </td>
                          </tr>
                        );
                      });
                    })}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
              {bought ? (
                <Table
                  striped
                  bordered
                  hover
                  size="sm"
                  style={{ marginBottom: "15%" }}
                >
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Product</th>
                      <th>Seller</th>
                      <th>Quantity</th>
                      <th>Total Price</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.current.map((item) => {
                      return item.slice(1).map((subitem) => {
                        return (
                          <tr>
                            <td>{subitem.id}</td>
                            <td>{subitem.product_name}</td>
                            <td>{subitem.seller_name}</td>
                            <td>{subitem.quantity}</td>
                            <td>
                              Rs.{" "}
                              {JSON.parse(subitem.product_price) *
                                JSON.parse(subitem.quantity)}
                              /-
                            </td>
                            <td>
                              {subitem.status == 1 ? "Delivered" : "Pending"}
                            </td>
                          </tr>
                        );
                      });
                    })}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <div className="container" style={{ textAlign: "center" }}>
            <h3>You don't have any orders!</h3>
          </div>
          <div
            className="admin-btn-p"
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20%",
            }}
          >
            <NavLink
              className="btn admin-btn"
              style={{ background: "#331a00", color: "white" }}
              to="/"
            >
              Back to Home
            </NavLink>
          </div>
        </>
      )}
    </>
  );
};

export default PrevOrders;
