import React, { useState, useEffect, useRef } from "react";
import { IconContext } from "react-icons";
import { AiOutlineSearch } from "react-icons/ai";

const Search = (props) => {
  const [, setData] = useState([]);
  const data = useRef([]);

  const handleChange = (e) => {
    props.setQuery(e.target.value);
  };

  const [search, setSearch] = useState("");

  const searchWord = () => {
    props.setQuery(search);
  };

  useEffect(() => {
    var axios = require("axios");
    var config = {
      method: "get",
      url: "http://aaryanik.com/api_web/productCategory.php",
      headers: {
        accept: "application/json",
      },
    };
    axios(config)
      .then(function (res) {
        data.current = Object.entries(res.data.data);
        setData(data.current);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data]);

  return (
    <>
      <div className="category">
        <div>
          <select
            style={{ borderRadius: "0.25rem", height: "40px" }}
            className="form-select"
            onChange={handleChange}
          >
            <option value="">Choose Product Category</option>
            {data.current.map((item) => {
              return item.slice(1).map((subitem) => {
                return (
                  <>
                    <option value={subitem.id}>
                      {subitem.product_category}
                    </option>
                  </>
                );
              });
            })}
          </select>
        </div>
        {search == "" ? props.setQuery("") : <></>}
        <div style={{ display: "inline-flex" }} id="search">
          <input
            id="search-input"
            style={{ height: "40px", border: "1px solid black" }}
            type="search"
            className="form-control"
            placeholder={"Search"}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <label className="form-label"></label>
          <button
            id="search-button"
            type="button"
            className="btn mt-0 ml-0"
            style={{
              width: "auto",
              height: "40px",
              border: "1px solid black",
              background: "#70ad46",
            }}
            onClick={searchWord}
          >
            <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
              <div className="searchButton">
                <AiOutlineSearch />
              </div>
            </IconContext.Provider>
          </button>
        </div>
      </div>
    </>
  );
};

export default Search;
