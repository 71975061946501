import React, { useState, useEffect, useRef } from "react";
import { Table } from "react-bootstrap";

const Orderhistory = () => {
  const [pending, setPending] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [advanced, setAdvanced] = useState(false);

  const showPending = () => {
    setPending(true);
    setCompleted(false);
    setAdvanced(false);
  };

  const showCompleted = () => {
    setPending(false);
    setCompleted(true);
    setAdvanced(false);
  };

  const showAdvanced = () => {
    setPending(false);
    setCompleted(false);
    setAdvanced(true);
  };

  const [, setData] = useState([]);
  const data = useRef([]);

  const [, setData1] = useState([]);
  const data1 = useRef([]);

  useEffect(() => {
    var axios = require("axios");
    var config = {
      method: "get",
      url: "http://aaryanik.com/api_web/pendingOrders.php",
      headers: {
        accept: "application/json",
      },
    };
    axios(config)
      .then(function (res) {
        data.current = Object.entries(res.data.data);
        setData(data.current);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data]);

  useEffect(() => {
    var axios1 = require("axios");
    var config1 = {
      method: "get",
      url: "http://aaryanik.com/api_web/completedOrders.php",
      headers: {
        accept: "application/json",
      },
    };
    axios1(config1)
      .then(function (res) {
        data1.current = Object.entries(res.data.data);
        setData1(data1.current);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data1]);

  return (
    <div style={{ marginBottom: "15%" }}>
      <div className="order">
        <h1>Order History</h1>
        <div
          className="admin-btn-p"
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          <button className="btn admin-btn" onClick={showPending}>
            Pending Orders
          </button>
          <button className="btn admin-btn" onClick={showCompleted}>
            Completed Orders
          </button>
          <button className="btn admin-btn" onClick={showAdvanced}>
            Advanced Orders
          </button>
          <hr />
        </div>
      </div>

      {pending ? (
        <>
          <h2 style={{ textAlign: "center" }}>Pending orders</h2>
          <Table
            striped
            bordered
            hover
            size="sm"
            style={{ marginBottom: "15%" }}
          >
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Product</th>
                <th>Seller</th>
                <th>Buyer</th>
                <th>Quantity</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {data.current.map((item) => {
                return item.slice(1).map((subitem) => {
                  return (
                    <tr>
                      <td>{subitem.id}</td>
                      <td>{subitem.product_name}</td>
                      <td>{subitem.seller_name}</td>
                      <td>{subitem.buyer_name}</td>
                      <td>{subitem.quantity}</td>
                      <td>
                        Rs.{" "}
                        {JSON.parse(subitem.product_price) *
                          JSON.parse(subitem.quantity)}
                        /-
                      </td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <></>
      )}

      {completed ? (
        <>
          <h2 style={{ textAlign: "center" }}>Completed orders</h2>
          <Table
            striped
            bordered
            hover
            size="sm"
            style={{ marginBottom: "15%" }}
          >
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Product</th>
                <th>Seller</th>
                <th>Buyer</th>
                <th>Quantity</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {data1.current.map((item) => {
                return item.slice(1).map((subitem) => {
                  return (
                    <tr>
                      <td>{subitem.id}</td>
                      <td>{subitem.product_name}</td>
                      <td>{subitem.seller_name}</td>
                      <td>{subitem.buyer_name}</td>
                      <td>{subitem.quantity}</td>
                      <td>
                        Rs.{" "}
                        {JSON.parse(subitem.product_price) *
                          JSON.parse(subitem.quantity)}
                        /-
                      </td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <></>
      )}

      {advanced ? (
        <>
          <h2 style={{ textAlign: "center" }}>Advanced orders</h2>
          <Table
            striped
            bordered
            hover
            size="sm"
            style={{ marginBottom: "15%" }}
          >
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Product</th>
                <th>Seller</th>
                <th>Buyer</th>
                <th>Quantity</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {data1.current.map((item) => {
                return item.slice(1).map((subitem) => {
                  return (
                    <tr>
                      <td>{subitem.id}</td>
                      <td>{subitem.product_name}</td>
                      <td>{subitem.seller_name}</td>
                      <td>{subitem.buyer_name}</td>
                      <td>{subitem.quantity}</td>
                      <td>
                        Rs.{" "}
                        {JSON.parse(subitem.product_price) *
                          JSON.parse(subitem.quantity)}
                        /-
                      </td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Orderhistory;
