import React, { useState } from "react";
import "./contact.css";

const Contact = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");

  const submitQuery = () => {
    var axios = require("axios");

    let formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", emailAddress);
    formData.append("phone_no", phoneNumber);
    formData.append("address", address);
    formData.append("message", message);

    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/contactUs.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        let num = response.data.success;

        let num1 = parseInt(num, 10);

        if (num1 === 515) {
          setFirstName("");
          setLastName("");
          setEmailAddress("");
          setAddress("");
          setPhoneNumber("");
          setMessage("");
          alert("Email Not sent");
        }
        if (num1 === 200) {
          setFirstName("");
          setLastName("");
          setEmailAddress("");
          setAddress("");
          setPhoneNumber("");
          setMessage("");
          alert("Email Sent");
        }
      })
      .catch(function (error) {
        setFirstName("");
        setLastName("");
        setEmailAddress("");
        setAddress("");
        setPhoneNumber("");
        setMessage("");
        console.log("Technical problem.Not registered");
      });
  };

  return (
    <React.Fragment>
      <div
        className="vision"
        style={{ height: "max-content", marginBottom: "15%" }}
      >
        <div className="left" style={{ padding: "8px 0 auto 0" }}>
          <h4>aaryanik agro industries fPC ltd</h4>
          <h5>
            Address: Plot no. 3, Near sai mandir, <br />
            Pragatisheel colony, Wardha road,
            <br />
            Nagpur - 440015 <br />
            Contact: 9890657420 <br />
            Email: aaryanik.agro@gmail.com
          </h5>
        </div>
        <section className="right" style={{ paddingTop: "8px" }}>
          <h1 style={{ margin: "0 ", color: "white" }}>contact us</h1>

          <input
            className="input"
            name="firstName"
            placeholder="First Name"
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <input
            className="input"
            name="lastName"
            placeholder="Last Name"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <input
            className="input"
            name="email"
            type="email"
            placeholder="Email"
            required
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          <input
            className="input"
            name="phoneNumber"
            placeholder="Phone Number"
            required
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <input
            className="input"
            name="address"
            placeholder="Address"
            required
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <textarea
            name="message"
            placeholder="Message"
            required
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="text-center">
            <input
              type="submit"
              className="button"
              value="Submit"
              onClick={submitQuery}
            />
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Contact;
