import React from "react";

const BgImage = () => {
  return (
    <div style={{ height: "100%" }}>
      <img src="./img/bg-img.png" alt="" className="bg-img" />
    </div>
  );
};

export default BgImage;
