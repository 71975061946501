import React, { useState } from "react";
import { NavLink, Redirect } from "react-router-dom";

const Logout = () => {
  let u_id = localStorage.getItem("user_id");
  let username = localStorage.getItem("username");

  const [logout, setLogout] = useState(false);

  if (logout) {
    localStorage.clear();
    return <Redirect exact to="/" />;
  }

  return (
    <div
      className="container contact"
      style={{ height: "fit-content", color: "white", textAlign: "center" }}
    >
      <h3>Are you sure you want to logout?</h3>
      <NavLink to="/" id="return" style={{ color: "white" }} className="btn">
        Return to website
      </NavLink>
      <a
        id="logout"
        className="btn"
        onClick={() => setLogout(true)}
        style={{ color: "white", display: "inline" }}
      >
        Confirm Logout
      </a>
    </div>
  );
};

export default Logout;
