import React, { useState } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { IconContext } from "react-icons";
import { CgProfile } from "react-icons/cg";
import axios from "axios";

const Seller = () => {
  const [type, setType] = useState("");
  const [redirect, setRedirect] = useState(false);

  let id = localStorage.getItem("user_id");
  console.log(id);
  const createAccount = () => {
    var axios = require("axios");
    let formData = new FormData();

    formData.append("id", id);

    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/sellerRequest.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    axios(config)
      .then(function (res) {
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        if (num1 === 200) {
          console.log("Status updated");
          showAlert("Request sent successfully");
        }
        if (num1 == 201) {
          showAlert("Request already exists");
        }

        if (num1 === 515) {
          showAlert("Request not sent successfully");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    setTimeout(() => {
      setRedirect(true);
    }, 500);
  };

  const showAlert = (mesg) => {
    alert(mesg);
  };

  return (
    <>
      {redirect ? <Redirect to="/"></Redirect> : <></>}
      <div className="needs-validation">
        <div style={{ marginBottom: "5rem" }}>
          <article className="register mt-3 mb-3">
            <div>
              <h3
                className="card-title mt-3 text-center"
                style={{
                  color: "white",
                  textAlign: "center",
                  marginBottom: "10%",
                }}
              >
                Create Seller Account
              </h3>
              <div className="register-form" style={{ width: "80%" }}>
                <div className="row">
                  <div
                    className="col-lg"
                    style={{ display: "inline-flex", padding: "0" }}
                  >
                    <div
                      className="input-group-text"
                      style={{
                        margin: "0",
                        padding: "0",
                        borderTopRightRadius: "0",
                        borderBottomRightRadius: "0",
                      }}
                    >
                      <h6 style={{ margin: "0 1rem 0 1rem", color: "black" }}>
                        Seller Type:{" "}
                      </h6>
                    </div>
                    <select
                      className="register-select"
                      style={{ margin: "0", padding: "0" }}
                      onChange={(e) => setType(e.target.value)}
                      required
                    >
                      <option value="" selected>
                        Select
                      </option>
                      <option value="individual">Individual</option>
                      <option value="company">Company / Organization</option>
                      <option value="wholesaler">Wholesaler</option>
                      <option value="retailer">Retailer</option>
                    </select>
                  </div>

                  {type === "company" ? (
                    <div
                      className="form-group input-group"
                      style={{ marginTop: "10px" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <IconContext.Provider
                            value={{ color: "black", size: "1.25em" }}
                          >
                            <div>
                              <CgProfile />
                            </div>
                          </IconContext.Provider>
                        </span>
                      </div>
                      <input
                        name="Company Name"
                        className="form-control"
                        placeholder="Company Name"
                        type="text"
                        required
                      />
                    </div>
                  ) : (
                    console.log(type)
                  )}
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-block"
                    style={{
                      background: "#70ad46",
                      color: "white",
                      width: "40%",
                      margin: "auto",
                      marginTop: "5%",
                    }}
                    onClick={createAccount}
                  >
                    Create Account
                  </button>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default Seller;
