import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

const AdminProduct = () => {
  const productid = localStorage.getItem("productid");

  const [, setData] = useState([]);
  const data = useRef([]);

  useEffect(() => {
    var axios = require("axios");
    let formData = new FormData();
    formData.append("productid", productid);
    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/adminProduct.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    axios(config)
      .then(function (res) {
        data.current = Object.entries(res.data.data);
        setData(data.current);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data]);

  const handleAccept = (id) => {
    var axios3 = require("axios");
    let formData = new FormData();

    formData.append("id", id);
    var config3 = {
      method: "post",
      url: "http://aaryanik.com/api_web/handleAccept.php",
      headers: {
        accept: "application/json",
      },
      data: formData,
    };
    axios3(config3)
      .then(function (res) {
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        if (num1 === 200) {
          console.log("Status updated");
          showAlert("Status updated successfully");
        }
        if (num1 === 515) {
          console.log("Status not updates");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleReject = (id) => {
    var axios4 = require("axios");
    let formData = new FormData();
    formData.append("id", id);
    var config4 = {
      method: "post",
      url: "http://aaryanik.com/api_web/handleReject.php",
      headers: {
        accept: "application/json",
      },
      data: formData,
    };
    axios4(config4)
      .then(function (res) {
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        if (num1 === 200) {
          console.log("Status updated");
          showAlert("Status updated successfully");
        }
        if (num1 === 515) {
          console.log("Status not updated");
        }
      })

      .catch(function (error) {
        console.log(error);
      });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const showAlert = (mesg) => {
    alert(mesg);
  };

  return (
    <React.Fragment>
      {data.current.map((item) => {
        return item.slice(1).map((product) => {
          return (
            <div className="container">
              <h1 style={{ color: "#343a40", textAlign: "center" }}>
                {product.product_name}
              </h1>
              <div className="details">
                <img
                  className="details-img"
                  src={product.product_image}
                  alt=""
                />
              </div>
              <hr />
              <h5 style={{ textAlign: "center" }}>
                Seller: {product.full_name}
              </h5>
              <hr />
              <h5 style={{ textAlign: "center" }}>
                Category: {product.product_category}
              </h5>
              <hr />
              <div style={{ textAlign: "center" }}>
                <h5 style={{ display: "inline" }}>
                  Price: {product.product_price} {product.unit}
                </h5>
              </div>
              <hr />
              <p style={{ textAlign: "center", fontSize: "large" }}>
                {product.product_desc}
              </p>
              <hr />
              <form style={{ textAlign: "center" }}>
                <input
                  type="radio"
                  name="approval"
                  id="accept"
                  value="1"
                  onChange={() => handleAccept(product.id)}
                />
                  <label for="accept">Accept</label>
                <input
                  type="radio"
                  name="approval"
                  id="reject"
                  value="2"
                  style={{ marginLeft: "5%" }}
                  onChange={() => handleReject(product.id)}
                />
                  <label for="reject">Reject</label>
              </form>
              <hr />
              <div style={{ textAlign: "center", marginBottom: "20%" }}>
                <NavLink
                  className="btn inc"
                  style={{
                    background: "#331a00",
                    color: "white",
                    marginRight: "20px",
                  }}
                  to="/approvals"
                >
                  Back to Approvals
                </NavLink>
              </div>
            </div>
          );
        });
      })}
    </React.Fragment>
  );
};

export default AdminProduct;
