import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";

const Checkout = () => {
  let total_amount = 0;
  const u_id = localStorage.getItem("user_id");

  const [, setData] = useState([]);
  const data = useRef([]);
  useEffect(() => {
    var axios = require("axios");

    let formData = new FormData();
    formData.append("userid", u_id);

    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/cartItem.php",
      headers: {
        accept: "application/json",
      },
      data: formData,
    };
    axios(config)
      .then(function (res) {
        data.current = Object.entries(res.data.data);
        setData(data.current);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data]);

  return (
    <div className="container" style={{ marginBottom: "20%" }}>
      <h1 style={{ color: "#343a40" }}>Checkout</h1>
      <table
        className="table table-striped"
        style={{ marginBottom: "50px", marginTop: "7%" }}
      >
        <thead className="table table-dark">
          <tr>
            <th scope="col">Product</th>
            <th scope="col">Quantity</th>
            <th scope="col">Price</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          {data.current.map((item) => {
            return item.slice(1).map((subitem) => {
              total_amount +=
                JSON.parse(subitem.quantity) *
                JSON.parse(subitem.product_price);
              localStorage.setItem("total_amount", total_amount);
              return (
                <tr key={subitem.p_id}>
                  <td>{subitem.product_name}</td>
                  <td>{subitem.quantity}</td>
                  <td>
                    Rs. {subitem.product_price} {subitem.unit}
                  </td>
                  <td>
                    Rs.{" "}
                    {JSON.parse(subitem.quantity) *
                      JSON.parse(subitem.product_price)}
                    /-
                  </td>
                </tr>
              );
            });
          })}
          <tr>
            <td colSpan="3">Total Amount</td>
            <td>Rs. {total_amount}/-</td>
          </tr>
        </tbody>
      </table>
      <div
        className="admin-btn-p"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        <NavLink
          className="btn admin-btn"
          style={{ background: "#331a00", color: "white" }}
          to="/cart"
        >
          Back to Cart
        </NavLink>
        <NavLink
          className="btn admin-btn"
          style={{ background: "#331a00", color: "white" }}
          to="/payment"
        >
          Proceed for Payment
        </NavLink>
      </div>
    </div>
  );
};

export default Checkout;
