import React, { useEffect, useState, useRef } from "react";
import { Redirect } from "react-router-dom";

const Cards = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  const u_id = localStorage.getItem("user_id");

  const details = (subitem) => {
    localStorage.setItem("subitem", JSON.stringify(subitem));
    setShowDetails(true);
  };

  const [, setData] = useState([]);
  const data = useRef([]);

  useEffect(() => {
    var axios = require("axios");
    var config = {
      method: "get",
      url: "http://aaryanik.com/api_web/estore.php",
      headers: {
        accept: "application/json",
      },
    };
    axios(config)
      .then(function (res) {
        data.current = Object.entries(res.data.data);
        setData(data.current);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data]);

  return (
    <React.Fragment>
      <div className="row no-gutters mt-4" style={{ marginBottom: "4rem" }}>
        {data.current.map((item) => {
          return item.slice(1).map((subitem) => {
            if (
              (props.query === subitem.product_name.toLowerCase() ||
                props.query === subitem.product_name.toUpperCase() ||
                props.query === subitem.product_name ||
                subitem.product_name.toLowerCase().includes(props.query) ||
                subitem.product_name.toUpperCase().includes(props.query)) &&
              props.query !== ""
            )
              return (
                <div key={subitem.p_id} className="col-sm-6 col-md-4 col-lg-3">
                  <div
                    className="card zoom"
                    style={{ background: "#70ad46", color: "black" }}
                  >
                    <div className="card-body" style={{ height: "70%" }}>
                      <img
                        src={subitem.product_image}
                        className="card-img-top mb-3"
                        alt=""
                      />
                      <h5 className="card-title">{subitem.product_name}</h5>
                      <div>
                        <a
                          className="btn"
                          style={{ background: "#331a00", color: "white" }}
                          onClick={() => details(subitem)}
                        >
                          View Details
                        </a>
                        {showDetails && u_id ? (
                          <Redirect exact to="/details" />
                        ) : !u_id && showDetails ? (
                          <Redirect exact to="/details" />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            else if (subitem.product_category_id === props.query) {
              return (
                <div key={subitem.p_id} className="col-sm-6 col-md-4 col-lg-3">
                  <div
                    className="card zoom"
                    style={{ background: "#70ad46", color: "black" }}
                  >
                    <div className="card-body" style={{ height: "70%" }}>
                      <img
                        src={subitem.product_image}
                        className="card-img-top mb-3"
                        alt=""
                      />
                      <h5 className="card-title">{subitem.product_name}</h5>
                      <div>
                        <a
                          className="btn"
                          style={{ background: "#331a00", color: "white" }}
                          onClick={() => details(subitem)}
                        >
                          View Details
                        </a>
                        {showDetails && u_id ? (
                          <Redirect exact to="/details" />
                        ) : !u_id && showDetails ? (
                          <Redirect exact to="/details" />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (props.query === "") {
              return (
                <div key={subitem.p_id} className="col-sm-6 col-md-4 col-lg-3">
                  <div
                    className="card zoom"
                    style={{ background: "#70ad46", color: "black" }}
                  >
                    <div className="card-body" style={{ height: "70%" }}>
                      <img
                        src={subitem.product_image}
                        className="card-img-top mb-3"
                        alt=""
                      />
                      <h5 className="card-title">{subitem.product_name}</h5>
                      <div>
                        <a
                          className="btn"
                          style={{ background: "#331a00", color: "white" }}
                          onClick={() => details(subitem)}
                        >
                          View Details
                        </a>
                        {showDetails && u_id ? (
                          <Redirect exact to="/details" />
                        ) : !u_id && showDetails ? (
                          <Redirect exact to="/login" />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          });
        })}
      </div>
    </React.Fragment>
  );
};

export default Cards;
