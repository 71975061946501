import React, { useState, useEffect, useRef } from "react";
import { Redirect, NavLink } from "react-router-dom";

const Favorite = () => {
  const u_id = localStorage.getItem("user_id");

  const [count, setCount] = useState(false);

  const [showDetails, setShowDetails] = useState(false);

  const details = (subitem) => {
    localStorage.setItem("subitem", JSON.stringify(subitem));
    setShowDetails(true);
  };

  const [, setData] = useState([]);
  const data = useRef([]);

  useEffect(() => {
    var axios = require("axios");

    let formData = new FormData();
    formData.append("userid", u_id);

    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/displayFavorites.php",
      headers: {
        accept: "application/json",
      },
      data: formData,
    };
    axios(config)
      .then(function (res) {
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        if (num1 == 200) {
          data.current = Object.entries(res.data.data);
          setData(data.current);
          setCount(true);
        }
        if (num1 == 515) {
          setCount(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setCount(false);
      });
  }, [data]);

  return (
    <>
      <h1>Favorites</h1>
      {count ? (
        <>
          <div className="row no-gutters mt-4" style={{ marginBottom: "4rem" }}>
            {data.current.map((item) => {
              return item.slice(1).map((subitem) => {
                return (
                  <div
                    key={subitem.p_id}
                    className="col-sm-6 col-md-4 col-lg-3"
                  >
                    <div
                      className="card zoom"
                      style={{ background: "#70ad46", color: "black" }}
                    >
                      <div className="card-body" style={{ height: "70%" }}>
                        <img
                          src={subitem.product_image}
                          className="card-img-top mb-3"
                          alt=""
                        />
                        <h5 className="card-title">{subitem.product_name}</h5>
                        <div>
                          <a
                            className="btn"
                            style={{ background: "#331a00", color: "white" }}
                            onClick={() => details(subitem)}
                          >
                            View Details
                          </a>
                          {showDetails && u_id ? (
                            <Redirect exact to="/details" />
                          ) : !u_id && showDetails ? (
                            <Redirect exact to="/details" />
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              });
            })}
          </div>
        </>
      ) : (
        <>
          <div className="container" style={{ textAlign: "center" }}>
            <h3>You don't have any favorites!</h3>
          </div>
          <div
            className="admin-btn-p"
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20%",
            }}
          >
            <NavLink
              className="btn admin-btn"
              style={{ background: "#331a00", color: "white" }}
              to="/"
            >
              Back to Home
            </NavLink>
          </div>
        </>
      )}
    </>
  );
};

export default Favorite;
