import React, { useState } from "react";
import { IconContext } from "react-icons";
import { CgProfile } from "react-icons/cg";
import { HiOutlineMail } from "react-icons/hi";
import { FiPhoneCall, FiAtSign } from "react-icons/fi";
import { AiFillLock } from "react-icons/ai";
import { FaRegAddressCard } from "react-icons/fa";
import { Redirect, NavLink } from "react-router-dom";

const Register = () => {
  const [fullName, setFullName] = useState("");
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [userType, setUserType] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [isFullNameError, setIsFullNameError] = useState(false);
  const [isUserNameError, setIsUserNameError] = useState(false);
  const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
  const [isEmailAddressError, setIsEmailAddressError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isAddressError, setIsAddressError] = useState(false);
  const [isUserTypeError, setIsUserTypeError] = useState(false);

  const AuthenticateRegister = () => {
    if (fullName === "") {
      setIsFullNameError(true);
    } else {
      setIsFullNameError(false);
    }

    if (userName === "") {
      setIsUserNameError(true);
    } else {
      setIsUserNameError(false);
    }

    if (emailAddress === "") {
      console.log("email id = " + emailAddress);
      setIsEmailAddressError(true);
    } else {
      setIsEmailAddressError(false);
    }
    let pattern = new RegExp(
      /^(('[\w-\s]+')|([\w-]+(?:\.[\w-]+)*)|('[\w-\s]+')([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(emailAddress)) {
      setIsEmailAddressError(true);
    } else {
      setIsEmailAddressError(false);
    }

    let len = phoneNumber.length;
    if (len < 10) {
      setIsPhoneNumberError(true);
    } else {
      setIsPhoneNumberError(false);
    }

    var reg = /[A-Z]/;
    var test = reg.test(password);
    if (password === "" || test === false) {
      setIsPasswordError(true);
    } else {
      setIsPasswordError(false);
    }

    if (address === "") {
      setIsAddressError(true);
    } else {
      setIsAddressError(false);
    }

    if (userType === "") {
      setIsUserTypeError(true);
    } else {
      setIsUserTypeError(false);
    }

    if (
      fullName !== "" &&
      userName !== "" &&
      phoneNumber !== "" &&
      emailAddress !== "" &&
      password !== "" &&
      address !== "" &&
      userType !== ""
    ) {
      var axios = require("axios");

      let formData = new FormData();
      formData.append("full_name", fullName);
      formData.append("user_name", userName);
      formData.append("password", password);
      formData.append("mobile_number", phoneNumber);
      formData.append("email_id", emailAddress);
      formData.append("address", address);
      formData.append("user_type", userType);

      var config = {
        method: "post",
        url: "http://aaryanik.com/api_web/register.php",
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          let num = response.data.success;
          let num1 = parseInt(num, 10);
          console.log(num1);
          if (num1 === 201) {
            setFullName("");
            setUserName("");
            setPhoneNumber("");
            setEmailAddress("");
            setPassword("");
            setUserType("");
            setAddress("");

            setIsPhoneNumberError(false);
            setIsEmailAddressError(false);

            setMessage("User already exists");
          }
          if (num1 === 515) {
            setFullName("");
            setUserName("");
            setPhoneNumber("");
            setEmailAddress("");
            setPassword("");
            setUserType("");
            setAddress("");
            setMessage("Not registered successfully");
          }
          if (num1 === 200) {
            setMessage("Registered successfully");
            setIsSuccess(true);
          }
        })
        .catch(function (error) {
          console.log(error);
          setFullName("");
          setUserName("");
          setPhoneNumber("");
          setEmailAddress("");
          setPassword("");
          setUserType("");
          setAddress("");
          setMessage("Technical problem.Not registered");
        });
    }
  };

  return (
    <div className="needs-validation">
      <div style={{ marginBottom: "5rem" }}>
        <article className="register mt-3 mb-3">
          <div>
            <h3
              className="card-title mt-3 text-center"
              style={{
                color: "white",
                textAlign: "center",
                marginBottom: "10%",
              }}
            >
              Create Account
            </h3>
            {isSuccess ? (
              userType === "buyer" ? (
                <Redirect exact to="/login" />
              ) : (
                <Redirect exact to="/seller" />
              )
            ) : (
              <div>
                <center>
                  <h6 style={{ color: "red" }}>{message}</h6>
                </center>
              </div>
            )}
            <div className="register-form">
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <IconContext.Provider
                      value={{ color: "black", size: "1.25em" }}
                    >
                      <div>
                        <CgProfile />
                      </div>
                    </IconContext.Provider>
                  </span>
                </div>
                <input
                  name="Full Name"
                  className="form-control"
                  placeholder="Full name"
                  type="text"
                  onChange={(e) => setFullName(e.target.value)}
                  value={fullName}
                  required
                />
              </div>
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <IconContext.Provider
                      value={{ color: "black", size: "1.25em" }}
                    >
                      <div>
                        <FiAtSign />
                      </div>
                    </IconContext.Provider>
                  </span>
                </div>
                <input
                  name="User Name"
                  className="form-control"
                  placeholder="User Name"
                  type="text"
                  onChange={(e) => setUserName(e.target.value)}
                  value={userName}
                  required
                />
              </div>
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <IconContext.Provider
                      value={{ color: "black", size: "1.25em" }}
                    >
                      <div>
                        <FiPhoneCall />
                      </div>
                    </IconContext.Provider>
                  </span>
                </div>
                <input
                  name="Phone Number"
                  className="form-control"
                  placeholder="Phone number"
                  type="text"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phoneNumber}
                  required
                />
              </div>
              {isPhoneNumberError && (
                <div>
                  {phoneNumber ? (
                    <p className="para-register" style={{ color: "white" }}>
                      *Enter a valid Phone number
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <IconContext.Provider
                      value={{ color: "black", size: "1.25em" }}
                    >
                      <div>
                        <HiOutlineMail />
                      </div>
                    </IconContext.Provider>
                  </span>
                </div>
                <input
                  name="Email Address"
                  className="form-control"
                  placeholder="Email address"
                  type="email"
                  onChange={(e) => setEmailAddress(e.target.value)}
                  value={emailAddress}
                  required
                />
              </div>
              {isEmailAddressError && (
                <div>
                  {emailAddress ? (
                    <p className="para-register" style={{ color: "white" }}>
                      *Enter a valid email address
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <IconContext.Provider
                      value={{ color: "black", size: "1.25em" }}
                    >
                      <div>
                        <AiFillLock />
                      </div>
                    </IconContext.Provider>
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Create password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                />
              </div>
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <IconContext.Provider
                      value={{ color: "black", size: "1.25em" }}
                    >
                      <div>
                        <FaRegAddressCard />
                      </div>
                    </IconContext.Provider>
                  </span>
                </div>
                <input
                  name="Address"
                  className="form-control"
                  placeholder="Address"
                  type="address"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  required
                />
                <div className="invalid-feedback">Please choose a Address.</div>
              </div>
              <div className="row">
                <div className="col-lg" style={{ display: "inline-flex" }}>
                  <div
                    className="input-group-text"
                    style={{
                      margin: "0",
                      padding: "0",
                      borderTopRightRadius: "0",
                      borderBottomRightRadius: "0",
                    }}
                  >
                    <h6 style={{ margin: "0 1rem 0 1rem", color: "black" }}>
                      User Type:{" "}
                    </h6>
                  </div>
                  <select
                    className="register-select"
                    style={{ margin: "0", padding: "0" }}
                    onChange={(e) => setUserType(e.target.value)}
                    value={userType}
                    required
                  >
                    <option value="" selected>
                      Select
                    </option>
                    <option value="buyer">Buyer</option>
                    <option value="seller">Seller</option>
                    <option value="both">Both</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-block"
                  style={{
                    background: "#70ad46",
                    color: "white",
                    width: "40%",
                    margin: "auto",
                    marginTop: "5%",
                  }}
                  onClick={AuthenticateRegister}
                >
                  Create Account
                </button>
              </div>
            </div>

            <p className="text-center" style={{ color: "white" }}>
              Have an account?{" "}
              <NavLink to="/login" style={{ color: "white" }}>
                Log In
              </NavLink>
            </p>
          </div>
        </article>
      </div>
    </div>
  );
};

export default Register;
