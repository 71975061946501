import React, { useState, useEffect, useRef } from "react";
import { Table } from "react-bootstrap";
import { Redirect } from "react-router-dom";

const Approval = () => {
  let count = 0;

  const [redirect, setRedirect] = useState(false);

  const [pending, setPending] = useState(true);
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);

  const [seller, setSeller] = useState("false");
  const [product, setProduct] = useState("false");

  const [, setData] = useState([]);
  const data = useRef([]);

  const [, setData1] = useState([]);
  const data1 = useRef([]);

  const [, setData2] = useState([]);
  const data2 = useRef([]);

  const [, setData5] = useState([]);
  const data5 = useRef([]);

  const [, setData6] = useState([]);
  const data6 = useRef([]);

  const [, setData8] = useState([]);
  const data8 = useRef([]);

  const [, setData9] = useState([]);
  const data9 = useRef([]);

  const showSeller = () => {
    setSeller(true);
    setProduct(false);
  };

  const showProduct = () => {
    setProduct(true);
    setSeller(false);
  };

  const showPending = () => {
    setPending(true);
    setApproved(false);
    setRejected(false);
  };

  const showApproved = () => {
    setPending(false);
    setApproved(true);
    setRejected(false);
  };

  const showRejected = () => {
    setPending(false);
    setApproved(false);
    setRejected(true);
  };

  const handleAccept = (id) => {
    var axios3 = require("axios");
    let formData = new FormData();

    formData.append("id", id);
    var config3 = {
      method: "post",
      url: "http://aaryanik.com/api_web/handleAccept.php",
      headers: {
        accept: "application/json",
      },
      data: formData,
    };
    axios3(config3)
      .then(function (res) {
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        if (num1 === 200) {
          console.log("Status updated");
          showAlert("Status updated successfully");
        }
        if (num1 === 515) {
          console.log("Status not updates");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleReject = (id) => {
    var axios4 = require("axios");
    let formData = new FormData();
    formData.append("id", id);
    var config4 = {
      method: "post",
      url: "http://aaryanik.com/api_web/handleReject.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    axios4(config4)
      .then(function (res) {
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        if (num1 === 200) {
          console.log("Status updated");
          showAlert("Status updated successfully");
        }
        if (num1 === 515) {
          console.log("Status not updated");
        }
      })

      .catch(function (error) {
        console.log(error);
      });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  useEffect(() => {
    var axios = require("axios");
    var config = {
      method: "get",
      url: "http://aaryanik.com/api_web/addProductApproval.php",
      headers: {
        accept: "application/json",
      },
    };
    axios(config)
      .then(function (res) {
        data.current = Object.entries(res.data.data);
        setData(data.current);
        console.log(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data]);

  useEffect(() => {
    var axios1 = require("axios");
    var config1 = {
      method: "get",
      url: "http://aaryanik.com/api_web/approvedApprovals.php",
      headers: {
        accept: "application/json",
      },
    };
    axios1(config1)
      .then(function (res) {
        data1.current = Object.entries(res.data.data);
        setData1(data1.current);
        console.log(data1);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data1]);

  useEffect(() => {
    var axios2 = require("axios");
    var config2 = {
      method: "get",
      url: "http://aaryanik.com/api_web/rejectedApprovals.php",
      headers: {
        accept: "application/json",
      },
    };
    axios2(config2)
      .then(function (res) {
        data2.current = Object.entries(res.data.data);
        setData2(data2.current);
        console.log(data2);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data2]);

  const showAlert = (mesg) => {
    alert(mesg);
  };

  const viewDetails = (id) => {
    localStorage.setItem("productid", id);
    setRedirect(true);
  };

  useEffect(() => {
    var axios5 = require("axios");
    var config5 = {
      method: "get",
      url: "http://aaryanik.com/api_web/sellerPending.php",
      headers: {
        accept: "application/json",
      },
    };
    axios5(config5)
      .then(function (res) {
        data5.current = Object.entries(res.data.data);
        setData5(data5.current);
        console.log(data5);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data5]);

  const handleAcceptS = (id, user_id) => {
    var axios6 = require("axios");
    let formData = new FormData();

    formData.append("id", id);
    formData.append("userid", user_id);

    var config6 = {
      method: "post",
      url: "http://aaryanik.com/api_web/handleAcceptS.php",
      headers: {
        accept: "application/json",
      },
      data: formData,
    };
    axios6(config6)
      .then(function (res) {
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        console.log(res.data);
        if (num1 === 200) {
          console.log("Status updated");
          showAlert("Status updated successfully");
        }
        if (num1 === 515) {
          console.log("Status not updates");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleRejectS = (id) => {
    var axios7 = require("axios");
    let formData = new FormData();

    formData.append("id", id);
    var config7 = {
      method: "post",
      url: "http://aaryanik.com/api_web/handleRejectS.php",
      headers: {
        accept: "application/json",
      },
      data: formData,
    };
    axios7(config7)
      .then(function (res) {
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        if (num1 === 200) {
          console.log("Status updated");
          showAlert("Status updated successfully");
        }
        if (num1 === 515) {
          console.log("Status not updates");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  useEffect(() => {
    var axios8 = require("axios");
    var config8 = {
      method: "get",
      url: "http://aaryanik.com/api_web/sellerApproved.php",
      headers: {
        accept: "application/json",
      },
    };
    axios8(config8)
      .then(function (res) {
        data8.current = Object.entries(res.data.data);
        setData8(data8.current);
        console.log(data8);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data8]);

  useEffect(() => {
    var axios9 = require("axios");
    var config9 = {
      method: "get",
      url: "http://aaryanik.com/api_web/sellerRejected.php",
      headers: {
        accept: "application/json",
      },
    };
    axios9(config9)
      .then(function (res) {
        data9.current = Object.entries(res.data.data);
        setData9(data9.current);
        console.log(data9);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data9]);

  return (
    <>
      <div
        style={{
          textAlign: "center",
          zIndex: "9000",
          top: "9.6%",
          backgroundColor: "white",
        }}
      >
        <h1>Approval Requests</h1>
        <div style={{ marginTop: "5%" }}>
          <button className="btn admin-btn" onClick={showPending}>
            Pending Requests
          </button>
          <button className="btn admin-btn" onClick={showApproved}>
            Approved Requests
          </button>
          <button className="btn admin-btn" onClick={showRejected}>
            Rejected Requests
          </button>
        </div>
        <hr />
      </div>

      {redirect ? <Redirect to="/adminproduct" /> : <></>}

      {pending ? (
        product ? (
          <>
            <h2 style={{ textAlign: "center" }}>Pending</h2>
            <div
              style={{
                textAlign: "center",
                marginTop: "5%",
                marginBottom: "5%",
              }}
            >
              <button className="btn admin-btn" onClick={showProduct}>
                Add Product
              </button>
              <button className="btn admin-btn" onClick={showSeller}>
                Seller
              </button>
            </div>
            <Table
              striped
              bordered
              hover
              size="sm"
              style={{ marginBottom: "15%" }}
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Full Name</th>
                  <th>Product</th>
                  <th>View Product</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.current.map((item) => {
                  return item.slice(1).map((subitem) => {
                    count = count + 1;
                    return (
                      <tr>
                        <td>{count}</td>
                        <td>{subitem.full_name}</td>
                        <td>{subitem.product_name}</td>
                        <td>
                          <button
                            className="btn btn-success"
                            onClick={() => viewDetails(subitem.id)}
                          >
                            View Product
                          </button>
                        </td>
                        <td>
                          <form>
                            <input
                              type="radio"
                              name="approval"
                              id="accept"
                              value="1"
                              onChange={() => handleAccept(subitem.id)}
                            />
                              <label for="accept">Accept</label>
                            <input
                              type="radio"
                              name="approval"
                              id="reject"
                              value="2"
                              style={{ marginLeft: "5%" }}
                              onChange={() => handleReject(subitem.id)}
                            />
                              <label for="reject">Reject</label>
                          </form>
                        </td>
                      </tr>
                    );
                  });
                })}
              </tbody>
            </Table>
          </>
        ) : (
          <>
            <h2 style={{ textAlign: "center" }}>Pending</h2>
            <div
              style={{
                textAlign: "center",
                marginTop: "5%",
                marginBottom: "5%",
              }}
            >
              <button className="btn admin-btn" onClick={showProduct}>
                Add Product
              </button>
              <button className="btn admin-btn" onClick={showSeller}>
                Seller
              </button>
            </div>
            <Table
              striped
              bordered
              hover
              size="sm"
              style={{ marginBottom: "15%" }}
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Full Name</th>
                  <th>View Details</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data5.current.map((item) => {
                  return item.slice(1).map((subitem) => {
                    count = count + 1;
                    return (
                      <tr>
                        <td>{count}</td>
                        <td>{subitem.full_name}</td>
                        <td>To add a product</td>
                        <td>
                          <form>
                            <input
                              type="radio"
                              name="approval"
                              id="accept"
                              value="1"
                              onChange={() => handleAcceptS(subitem.id)}
                            />
                              <label for="accept">Accept</label>
                            <input
                              type="radio"
                              name="approval"
                              id="reject"
                              value="2"
                              style={{ marginLeft: "5%" }}
                              onChange={() => handleRejectS(subitem.id)}
                            />
                              <label for="reject">Reject</label>
                          </form>
                        </td>
                      </tr>
                    );
                  });
                })}
              </tbody>
            </Table>
            ) :(<></>)
          </>
        )
      ) : (
        <></>
      )}

      {approved ? (
        product ? (
          <>
            <h2 style={{ textAlign: "center" }}>Approved</h2>
            <div
              style={{
                textAlign: "center",
                marginTop: "5%",
                marginBottom: "5%",
              }}
            >
              <button className="btn admin-btn" onClick={showProduct}>
                Add Product
              </button>
              <button className="btn admin-btn" onClick={showSeller}>
                Seller
              </button>
            </div>
            <Table
              striped
              bordered
              hover
              size="sm"
              style={{ marginBottom: "15%" }}
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Full Name</th>
                  <th>Product</th>
                  <th>View Product</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data1.current.map((item) => {
                  return item.slice(1).map((subitem) => {
                    count = count + 1;
                    return (
                      <tr>
                        <td>{count}</td>
                        <td>{subitem.full_name}</td>
                        <td>{subitem.product_name}</td>
                        <td>
                          <button
                            className="btn btn-success"
                            onClick={() => viewDetails(subitem.id)}
                          >
                            View Product
                          </button>
                        </td>
                        <td>Approved</td>
                      </tr>
                    );
                  });
                })}
              </tbody>
            </Table>
          </>
        ) : (
          <>
            <h2 style={{ textAlign: "center" }}>Approved</h2>
            <div
              style={{
                textAlign: "center",
                marginTop: "5%",
                marginBottom: "5%",
              }}
            >
              <button className="btn admin-btn" onClick={showProduct}>
                Add Product
              </button>
              <button className="btn admin-btn" onClick={showSeller}>
                Seller
              </button>
            </div>
            <Table
              striped
              bordered
              hover
              size="sm"
              style={{ marginBottom: "15%" }}
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Full Name</th>
                  <th>View Details</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data8.current.map((item) => {
                  return item.slice(1).map((subitem) => {
                    count = count + 1;
                    return (
                      <tr>
                        <td>{count}</td>
                        <td>{subitem.full_name}</td>
                        <td>To become a seller</td>
                        <td>Approved</td>
                      </tr>
                    );
                  });
                })}
              </tbody>
            </Table>
            :(<></>)
          </>
        )
      ) : (
        <></>
      )}

      {rejected ? (
        product ? (
          <>
            <h2 style={{ textAlign: "center" }}>Rejected</h2>
            <div
              style={{
                textAlign: "center",
                marginTop: "5%",
                marginBottom: "5%",
              }}
            >
              <button className="btn admin-btn" onClick={showProduct}>
                Add Product
              </button>
              <button className="btn admin-btn" onClick={showSeller}>
                Seller
              </button>
            </div>
            <Table
              striped
              bordered
              hover
              size="sm"
              style={{ marginBottom: "15%" }}
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Full Name</th>
                  <th>Product</th>
                  <th>View Details</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data2.current.map((item) => {
                  return item.slice(1).map((subitem) => {
                    count = count + 1;
                    return (
                      <tr>
                        <td>{count}</td>
                        <td>{subitem.full_name}</td>
                        <td>{subitem.product_name}</td>
                        <td>
                          <button
                            className="btn btn-success"
                            onClick={() => viewDetails(subitem.id)}
                          >
                            View Product
                          </button>
                        </td>
                        <td>Rejected</td>
                      </tr>
                    );
                  });
                })}
              </tbody>
            </Table>
          </>
        ) : (
          <>
            <h2 style={{ textAlign: "center" }}>Rejected</h2>
            <div
              style={{
                textAlign: "center",
                marginTop: "5%",
                marginBottom: "5%",
              }}
            >
              <button className="btn admin-btn" onClick={showProduct}>
                Add Product
              </button>
              <button className="btn admin-btn" onClick={showSeller}>
                Seller
              </button>
            </div>
            <Table
              striped
              bordered
              hover
              size="sm"
              style={{ marginBottom: "15%" }}
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Full Name</th>
                  <th>View Details</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data9.current.map((item) => {
                  return item.slice(1).map((subitem) => {
                    count = count + 1;
                    return (
                      <tr>
                        <td>{count}</td>
                        <td>{subitem.full_name}</td>
                        <td>To become a seller</td>
                        <td>Rejected</td>
                      </tr>
                    );
                  });
                })}
              </tbody>
            </Table>
            :(<></>)
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default Approval;
