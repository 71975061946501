import React from "react";
import Search from "./search";

const Estore = (props) => {
  return (
    <>
      <h1>E-Store</h1>
      <Search setQuery={props.setQuery} />
    </>
  );
};

export default Estore;
