import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";

const Notifications = () => {
  let user_id = localStorage.getItem("user_id");
  let count = 0;

  let count_np = 0;
  let count_ns = 0;
  const [, setData] = useState([]);
  const data = useRef([]);

  const [, setData5] = useState([]);
  const data5 = useRef([]);

  const [value, setValue] = useState();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    var axios = require("axios");
    let formData = new FormData();
    formData.append("userid", user_id);
    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/sellerNotification.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    axios(config)
      .then(function (res) {
        // console.log(JSON.stringify(res.data));
        data.current = Object.entries(res.data.data);
        setData(data.current);
        console.log(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data]);

  useEffect(() => {
    var axios5 = require("axios");
    let formData = new FormData();
    formData.append("userid", user_id);
    var config5 = {
      method: "post",
      url: "http://aaryanik.com/api_web/userPending.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    axios5(config5)
      .then(function (res) {
        data5.current = Object.entries(res.data.data);
        setData5(data5.current);
        console.log(data5);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data5]);

  data.current.map((item) => {
    return item.slice(1).map((subitem) => {
      count_np += 1;
    });
  });

  data5.current.map((item) => {
    return item.slice(1).map((subitem) => {
      count_ns += 1;
    });
  });

  let count1 = count_ns + count_np;

  const showAlert = (mesg) => {
    alert(mesg);
  };

  const viewDetails = (id) => {
    localStorage.setItem("productid", id);
    setRedirect(true);
  };

  return (
    <React.Fragment>
      <h2 style={{ textAlign: "center", marginTop: "5%" }}>Notifications</h2>
      {count1 > 0 ? (
        <>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th style={{ width: "10%" }}>S. No.</th>
                <th style={{ width: "40%" }}>Details</th>
                <th></th>
                <th>Status</th>
              </tr>
            </thead>
            {redirect ? <Redirect to="/userProduct" /> : <></>}

            {data.current.map((item) => {
              return item.slice(1).map((subitem) => {
                count = count + 1;
                return (
                  <>
                    {subitem.is_approved === "1" ? (
                      <>
                        <tr>
                          <td>{count}</td>
                          <td>Your product has been approved</td>
                          <td>
                            <button
                              className="btn btn-success"
                              onClick={() => viewDetails(subitem.id)}
                            >
                              View Product
                            </button>
                          </td>
                          <td>Approved</td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {subitem.is_approved === "2" ? (
                          <>
                            <tr>
                              <td>{count}</td>
                              <td>Your product has been rejected</td>

                              <td>
                                <button
                                  className="btn btn-success"
                                  onClick={() => viewDetails(subitem.id)}
                                >
                                  View Product
                                </button>
                              </td>
                              <td>Rejected</td>
                            </tr>
                          </>
                        ) : (
                          <>
                            <tr>
                              <td>{count}</td>
                              <td>Your product is pending</td>

                              <td>
                                <button
                                  className="btn btn-success"
                                  onClick={() => viewDetails(subitem.id)}
                                >
                                  View Product
                                </button>
                              </td>
                              <td>Pending</td>
                            </tr>
                          </>
                        )}
                      </>
                    )}
                  </>
                );
              });
            })}
            {data5.current.map((item) => {
              return item.slice(1).map((subitem) => {
                count = count + 1;
                return (
                  <>
                    <tr>
                      <td>{count}</td>
                      <td>Name : {subitem.full_name}</td>
                      <td>To become a seller</td>
                      <td>
                        {subitem.is_status === "0" ? (
                          <>Pending</>
                        ) : (
                          <>
                            {subitem.is_status === "1" ? (
                              <>Approved</>
                            ) : (
                              <>Rejected</>
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  </>
                );
              });
            })}
          </Table>
          <div
            className="admin-btn-p"
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20%",
            }}
          >
            <NavLink
              className="btn admin-btn"
              style={{ background: "#331a00", color: "white" }}
              to="/"
            >
              Back to Home
            </NavLink>
          </div>
        </>
      ) : (
        <>
          <div className="container" style={{ textAlign: "center" }}>
            <h3>You don't have any notifications!</h3>
          </div>
          <div
            className="admin-btn-p"
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20%",
            }}
          >
            <NavLink
              className="btn admin-btn"
              style={{ background: "#331a00", color: "white" }}
              to="/"
            >
              Back to Home
            </NavLink>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default Notifications;
