import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";

const UserProduct = () => {
  const productid = localStorage.getItem("productid");

  const [, setData1] = useState([]);
  const data1 = useRef([]);

  useEffect(() => {
    var axios1 = require("axios");
    let formData = new FormData();
    formData.append("productid", productid);
    var config1 = {
      method: "post",
      url: "http://aaryanik.com/api_web/adminProduct.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    axios1(config1)
      .then(function (res) {
        data1.current = Object.entries(res.data.data);
        setData1(data1.current);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [data1]);
  return (
    <React.Fragment>
      {data1.current.map((item) => {
        return item.slice(1).map((product) => {
          return (
            <div className="container">
              <h1 style={{ color: "#343a40", textAlign: "center" }}>
                {product.product_name}
              </h1>
              <div className="details">
                <img
                  className="details-img"
                  src={product.product_image}
                  alt=""
                />
              </div>
              <hr />

              <h5 style={{ textAlign: "center" }}>
                Category: {product.product_category}
              </h5>
              <hr />
              <div style={{ textAlign: "center" }}>
                <h5 style={{ display: "inline" }}>
                  Price: {product.product_price} {product.unit}
                </h5>
              </div>
              <hr />
              <p style={{ textAlign: "center", fontSize: "large" }}>
                {product.product_desc}
              </p>
              <hr />

              <div style={{ textAlign: "center", marginBottom: "20%" }}>
                <NavLink
                  className="btn inc"
                  style={{
                    background: "#331a00",
                    color: "white",
                    marginRight: "20px",
                  }}
                  to="/notifications"
                >
                  Back to Notifications
                </NavLink>
              </div>
            </div>
          );
        });
      })}
    </React.Fragment>
  );
};

export default UserProduct;
