import React from "react";
import { data } from "./data";

const About = () => {
  return (
    <React.Fragment>
      <div className="about">
        <h1 style={{ margin: "0 0 2rem", color: "white" }}>about us</h1>
        {data.map((data) => {
          const { id, para } = data;
          return <p key={id}>{para}</p>;
        })}
      </div>
    </React.Fragment>
  );
};

export default About;
