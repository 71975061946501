import React, { useEffect, useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const MyProducts = () => {
  let u_id = localStorage.getItem("user_id");

  const [count, setCount] = useState(false);

  const [, setData] = useState([]);
  const data = useRef([]);

  const [, setData2] = useState([]);
  const data2 = useRef([]);

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);

  const [pCategory, setPCategory] = useState("");
  const [pName, setPName] = useState("");
  const [pImage, setPImage] = useState();
  const [pPrice, setPPrice] = useState("");
  const [pUnit, setPUnit] = useState("");
  const [pDesc, setPDesc] = useState("");
  const [pExpiry, setPExpiry] = useState("");

  const [pCategory1, setPCategory1] = useState("");
  const [pName1, setPName1] = useState("");
  const [pImage1, setPImage1] = useState();
  const [pPrice1, setPPrice1] = useState("");
  const [pUnit1, setPUnit1] = useState("");
  const [pDesc1, setPDesc1] = useState("");
  const [pExpiry1, setPExpiry1] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showAlert = (mesg) => {
    alert(mesg);
  };

  const handleEdit = (id) => {
    setEdit(true);
    var axios2 = require("axios");

    let formData = new FormData();
    formData.append("productid", id);

    var config2 = {
      method: "post",
      url: "http://aaryanik.com/api_web/displayProduct.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios2(config2)
      .then(function (res) {
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        if (num1 === 200) {
          data2.current = Object.entries(res.data.data);
          setData2(data2.current);
          data2.current.map((item) => {
            item.slice(1).map((subitem) => {
              setPCategory(subitem.product_category);
              setPName(subitem.product_name);
              setPImage(subitem.product_image);
              setPPrice(subitem.product_price);
              setPUnit(subitem.unit);
              setPDesc(subitem.product_desc);
            });
          });
          console.log("Get product data");
        }
        if (num1 === 515) {
          console.log("Not set product data");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleEditClose = () => setEdit(false);

  const handleRemove = (id) => {
    var axios3 = require("axios");

    let formData = new FormData();
    formData.append("productid", id);

    var config3 = {
      method: "post",
      url: "http://aaryanik.com/api_web/deleteProduct.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios3(config3)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        console.log(num1);
        if (num1 === 200) {
          console.log("Product deleted");
          showAlert("Product deleted successfully");
        }
        if (num1 === 515) {
          console.log("Product not deleted");
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const saveAddProduct = () => {
    setShow(false);
    var axios4 = require("axios");

    let formData = new FormData();
    formData.append("user_id", u_id);
    formData.append("product_category", pCategory1);
    formData.append("product_name", pName1);
    formData.append("product_price", pPrice1);
    formData.append("product_unit", pUnit1);
    formData.append("product_desc", pDesc1);
    formData.append("product_expiry", pExpiry1);
    formData.append("product_image", pImage1);

    var config4 = {
      method: "post",
      url: "http://aaryanik.com/api_web/addProduct.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios4(config4)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        console.log(num1);
        if (num1 === 200) {
          console.log("Product added");
          showAlert("Product added successfully");
        }
        if (num1 === 515) {
          console.log("Product not added");
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleSave = (id) => {
    setEdit(false);
    var axios5 = require("axios");

    let formData = new FormData();
    formData.append("productid", id);
    formData.append("product_category", pCategory);
    formData.append("product_name", pName);
    formData.append("product_price", pPrice);
    formData.append("product_unit", pUnit);
    formData.append("product_desc", pDesc);
    formData.append("product_expiry", pExpiry);
    formData.append("product_image", pImage);

    var config5 = {
      method: "post",
      url: "http://aaryanik.com/api_web/updateProduct.php",
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    axios5(config5)
      .then(function (res) {
        console.log(res.data);
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        console.log(num);
        if (num1 === 200) {
          console.log("Product Edited");
          showAlert("Product updated successfully");
        }
        if (num1 === 515) {
          console.log("Product not edited");
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  useEffect(() => {
    var axios = require("axios");
    let formData = new FormData();
    formData.append("userid", u_id);
    var config = {
      method: "post",
      url: "http://aaryanik.com/api_web/displayUserProduct.php",
      headers: {
        accept: "application/json",
      },
      data: formData,
    };
    axios(config)
      .then(function (res) {
        var num = res.data.success;
        var num1 = parseInt(num, 10);
        if (num1 == 200) {
          data.current = Object.entries(res.data.data);
          setData(data.current);
          console.log(data);
          setCount(true);
        } else {
          setCount(false);
        }
      })
      .catch(function (error) {
        setCount(false);
      });
  }, [data]);

  return (
    <React.Fragment>
      <h1>my products</h1>
      {count ? (
        <>
          <div
            style={{
              paddingTop: "5%",
              paddingBottom: "5%",
              textAlign: "center",
            }}
          >
            <button
              className="btn"
              style={{
                background: "#331a00",
                color: "white",
              }}
              onClick={handleShow}
            >
              Add Products
            </button>
          </div>

          {show ? (
            <div>
              <Modal show={show} animation={false} style={{ zIndex: "10000" }}>
                <Modal.Header>
                  <Modal.Title>Add Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <input
                    name="category"
                    placeholder="Enter Category"
                    className="form-select"
                    type="text"
                    style={{ marginTop: "5%", width: "100%" }}
                    value={pCategory1}
                    onChange={(e) => setPCategory1(e.target.value)}
                  ></input>

                  <input
                    name="name"
                    placeholder="Enter Name"
                    className="form-select"
                    type="text"
                    style={{ marginTop: "5%", width: "100%" }}
                    value={pName1}
                    onChange={(e) => setPName1(e.target.value)}
                  ></input>

                  <input
                    name="price"
                    placeholder="Enter Price"
                    className="form-select"
                    type="text"
                    style={{ marginTop: "5%", width: "100%" }}
                    value={pPrice1}
                    onChange={(e) => setPPrice1(e.target.value)}
                  ></input>

                  <input
                    name="unit"
                    placeholder="Enter Unit"
                    className="form-select"
                    type="text"
                    style={{ marginTop: "5%", width: "100%" }}
                    value={pUnit1}
                    onChange={(e) => setPUnit1(e.target.value)}
                  ></input>

                  <textarea
                    name="description"
                    placeholder="Enter Description"
                    className="form-select"
                    type="text"
                    style={{ marginTop: "5%", width: "100%" }}
                    value={pDesc1}
                    onChange={(e) => setPDesc1(e.target.value)}
                  ></textarea>

                  <input
                    name="expiry"
                    placeholder="Enter Expiry"
                    className="form-select"
                    type="text"
                    style={{ marginTop: "5%", width: "100%" }}
                    value={pExpiry1}
                    onChange={(e) => setPExpiry1(e.target.value)}
                  ></input>

                  <form style={{ marginTop: "5%", width: "100%" }}>
                    <input
                      type="file"
                      id="img"
                      name="img"
                      accept="image/*"
                      style={{ marginTop: "3px" }}
                      onChange={(e) => setPImage1(e.target.files[0])}
                    />
                    {/* <input type="submit"></input> */}
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                    style={{ background: "#331A00" }}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={saveAddProduct}
                    style={{ background: "#331A00" }}
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          ) : (
            <div></div>
          )}

          {edit ? (
            <>
              {data2.current.map((item) => {
                return item.slice(1).map((subitem) => {
                  return (
                    <div>
                      <Modal
                        show={edit}
                        animation={false}
                        style={{ zIndex: "10000" }}
                      >
                        <Modal.Header>
                          <Modal.Title>Edit Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <input
                            name="category"
                            placeholder="Enter New Category"
                            className="form-select"
                            type="text"
                            style={{ marginTop: "5%", width: "100%" }}
                            value={pCategory}
                            onChange={(e) => setPCategory(e.target.value)}
                          ></input>

                          <input
                            name="name"
                            placeholder="Enter New Name"
                            className="form-select"
                            type="text"
                            style={{ marginTop: "5%", width: "100%" }}
                            value={pName}
                            onChange={(e) => setPName(e.target.value)}
                          ></input>

                          <input
                            name="price"
                            placeholder="Enter New Price"
                            className="form-select"
                            type="text"
                            style={{ marginTop: "5%", width: "100%" }}
                            value={pPrice}
                            onChange={(e) => setPPrice(e.target.value)}
                          ></input>

                          <input
                            name="unit"
                            placeholder="Enter New Unit"
                            className="form-select"
                            type="text"
                            style={{ marginTop: "5%", width: "100%" }}
                            value={pUnit}
                            onChange={(e) => setPUnit(e.target.value)}
                          ></input>

                          <textarea
                            name="description"
                            placeholder="Enter New Description"
                            className="form-select"
                            type="text"
                            style={{ marginTop: "5%", width: "100%" }}
                            value={pDesc}
                            onChange={(e) => setPDesc(e.target.value)}
                          ></textarea>

                          <input
                            name="expiry"
                            placeholder="Enter Expiry"
                            className="form-select"
                            type="text"
                            style={{ marginTop: "5%", width: "100%" }}
                            value={pExpiry}
                            onChange={(e) => setPExpiry(e.target.value)}
                          ></input>

                          <form style={{ marginTop: "5%", width: "100%" }}>
                            <img
                              width="40px"
                              height="40px"
                              style={{ marginRight: "2px" }}
                              src={pImage}
                            />
                            <input
                              type="file"
                              id="img"
                              name="img"
                              accept="image/*"
                              style={{ marginTop: "3px" }}
                              onChange={(e) => setPImage(e.target.files[0])}
                            />
                          </form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleEditClose}>
                            Close
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => handleSave(subitem.id)}
                          >
                            Save
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  );
                });
              })}
            </>
          ) : (
            <div></div>
          )}

          <div className="cartContainer">
            <table id="cart" className="table table-condensed">
              <thead className="table-dark">
                <tr>
                  <th style={{ width: "50%", paddingLeft: "2%" }}>Product</th>
                  <th style={{ width: "10%" }}>Price</th>
                  <th style={{ width: "10%" }}>Edit</th>
                </tr>
              </thead>
              {data.current.map((item) => {
                return item.slice(1).map((subitem) => {
                  return (
                    <tbody>
                      <tr id="productRow">
                        <td data-th="Product">
                          <div className="row">
                            <div className="col-sm-2 hidden-xs">
                              <img
                                src={subitem.product_image}
                                style={{ width: "100px", height: "100px" }}
                                className="img-responsive"
                              />
                            </div>
                            <div id="product-body" className="col-sm-10">
                              <h4 className="nomargin">
                                {subitem.product_name}
                              </h4>
                              <div
                                style={{ display: "flex", paddingTop: "1%" }}
                              >
                                <h5>Description </h5>
                                <p style={{ marginRight: "3%" }}>
                                  {" "}
                                  - {subitem.product_desc}
                                </p>
                              </div>
                              <div
                                style={{ display: "flex", paddingTop: "1%" }}
                              >
                                <h5>Category</h5>
                                <p>- {subitem.product_category}</p>
                              </div>
                              <h5>Expiry - 10 days</h5>
                            </div>
                          </div>
                        </td>
                        <td data-th="Price">
                          Rs. {subitem.product_price} {subitem.unit}
                        </td>

                        <td data-th="Edit">
                          <div
                            className="product"
                            style={{ textAlign: "left", display: "inline" }}
                          >
                            <button
                              className="btn"
                              style={{
                                background: "#331a00",
                                color: "white",
                              }}
                              onClick={() => handleEdit(subitem.id)}
                            >
                              Edit
                            </button>
                            <button
                              id="remove-prod-btn"
                              className="btn"
                              style={{
                                background: "#331a00",
                                color: "white",
                              }}
                              onClick={() => handleRemove(subitem.id)}
                            >
                              Remove
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  );
                });
              })}
            </table>
            <hr></hr>
          </div>
        </>
      ) : (
        <>
          <div className="container" style={{ textAlign: "center" }}>
            <h3>You don't have any products!</h3>
          </div>
          <div
            className="admin-btn-p"
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20%",
            }}
          >
            <NavLink
              className="btn admin-btn"
              style={{ background: "#331a00", color: "white" }}
              to="/"
            >
              Back to Home
            </NavLink>
            <button
              className="btn admin-btn"
              style={{
                background: "#331a00",
                color: "white",
              }}
              onClick={handleShow}
            >
              Add Products
            </button>
          </div>
          {show ? (
            <div>
              <Modal show={show} animation={false} style={{ zIndex: "10000" }}>
                <Modal.Header>
                  <Modal.Title>Add Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <input
                    name="category"
                    placeholder="Enter Category"
                    className="form-select"
                    type="text"
                    style={{ marginTop: "5%", width: "100%" }}
                    value={pCategory1}
                    onChange={(e) => setPCategory1(e.target.value)}
                  ></input>

                  <input
                    name="name"
                    placeholder="Enter Name"
                    className="form-select"
                    type="text"
                    style={{ marginTop: "5%", width: "100%" }}
                    value={pName1}
                    onChange={(e) => setPName1(e.target.value)}
                  ></input>

                  <input
                    name="price"
                    placeholder="Enter Price"
                    className="form-select"
                    type="text"
                    style={{ marginTop: "5%", width: "100%" }}
                    value={pPrice1}
                    onChange={(e) => setPPrice1(e.target.value)}
                  ></input>

                  <input
                    name="unit"
                    placeholder="Enter Unit"
                    className="form-select"
                    type="text"
                    style={{ marginTop: "5%", width: "100%" }}
                    value={pUnit1}
                    onChange={(e) => setPUnit1(e.target.value)}
                  ></input>

                  <textarea
                    name="description"
                    placeholder="Enter Description"
                    className="form-select"
                    type="text"
                    style={{ marginTop: "5%", width: "100%" }}
                    value={pDesc1}
                    onChange={(e) => setPDesc1(e.target.value)}
                  ></textarea>

                  <input
                    name="expiry"
                    placeholder="Enter Expiry"
                    className="form-select"
                    type="text"
                    style={{ marginTop: "5%", width: "100%" }}
                    value={pExpiry1}
                    onChange={(e) => setPExpiry1(e.target.value)}
                  ></input>

                  <form style={{ marginTop: "5%", width: "100%" }}>
                    <input
                      type="file"
                      id="img"
                      name="img"
                      accept="image/*"
                      style={{ marginTop: "3px" }}
                      onChange={(e) => setPImage1(e.target.files[0])}
                    />
                    {/* <input type="submit"></input> */}
                  </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                    style={{ background: "#331A00" }}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={saveAddProduct}
                    style={{ background: "#331A00" }}
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          ) : (
            <div></div>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default MyProducts;
